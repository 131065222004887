import React, { Component } from 'react';
import ReactTable from 'components/table/Table'
import MyInput from 'components/controls/MyInput';
import NumberFormat from "react-number-format";

import { IconMarin } from 'views/popup/SvgSprite';
import TextArea from 'components/controls/TextArea';
import DropdownList from 'components/controls/DropdownList';
import FileUpload from 'components/controls/FileUpload';
import _validator from 'modules/validator';
import downloadIcon from 'assets/img/download.svg';
import ButtonComponent from 'components/controls/Button';
import DownloadTemplate from 'views/popup/DownloadTemplate';
import _languageService from 'modules/LanguageService';
import _analysisService from 'modules/AnalysisService';
import { Spin, Modal, Tooltip, Input, Button, AutoComplete, Radio, Select, InputNumber } from 'antd';
import {
  GENERAL_ERROR_MESSAGE, SHOW_GROUP_LEVEL_1, SHOW_GROUP_LEVEL_2,
  GROUP_LEVEL_1_DISPLAY_TEXT, GROUP_LEVEL_2_DISPLAY_TEXT, ANALYSIS_MANUAL_ENTRY, DUBLICATE_REQUIRMENT_NO, ALL_FIELD_REQUIREMENT, ANALYZE_UPLOAD, SHEET_DROP_DOWN_ERROR,
  GROUP_LEVEL_3_DISPLAY_TEXT, GROUP_LEVEL_4_DISPLAY_TEXT, GROUP_LEVEL_5_DISPLAY_TEXT, SHOW_GROUP_LEVEL_3, SHOW_GROUP_LEVEL_4, SHOW_GROUP_LEVEL_5} from 'constants/constants';
import { routes } from 'routes/Routes';
import GroupSettings from 'views/popup/GroupSettings';
import _notification from 'modules/notification';
// import "antd/dist/antd.css"

const { Option } = Select;
const mockVal = (str, repeat = 1) => {
  return {
    value: str.repeat(repeat),
  };
};

let newDevelopmentOrEnhancementDropDwonArray = [
  { "Id": 0, "Name": "New Development" },
  { "Id": 1, "Name": "Enhancement" },
]

let fileDataHeader = [
  {
    "title": "First Name",
    "value": "First_Name",
    "dataIndex": "First_Name"
  },
  {
    "title": "Email Address",
    "value": "Email_address",
    "dataIndex": "Email_address"
  },
  {
    "title": "Home State",
    "value": "Home_State",
    "dataIndex": "Home_State"
  },
  {
    "title": "Home Address",
    "value": "Home_Address",
    "dataIndex": "Home_Address"
  }
]

const tempFileData = [
  {
    "first_name": "test",
    "last_name": "test",
    "mobile_no": "987654390",
    "email_id": "test@gmail.com",
    "linkedin_profile": "abc.com",
    "job_title": "test test",
    "home_address": "test test2",
    "home_city": "Pune",
    "home_state": "Mah"
  },
  {
    "first_name": "test2",
    "last_name": "test2name",
    "mobile_no": "987654390",
    "email_id": "test@gmail.com",
    "linkedin_profile": "abc.com",
    "job_title": "test test",
    "home_address": "test test2",
    "home_city": "Pune",
    "home_state": "Mah"
  }
]

let fileData = [
  {
    "First_Name": "test",
    "Last_Name": "test",
    "Mobile_Number": "987654390",
    "Email_address": "test@gmail.com",
    "Linkedin_Profile Link": "abc.com",
    "Current_Job_title": "test test",
    "Home_Address": "test test2",
    "Home_City": "Pune",
    "Home_State": "Mah"
  },
  {
    "First_Name": "test2",
    "Last_Name": "test2name",
    "Mobile_Number": "987654390",
    "Email_address": "test@gmail.com",
    "Linkedin_Profile_Link": "abc.com",
    "Current_Job_title": "test test",
    "Home_Address": "test test2",
    "Home_City": "Pune",
    "Home_State": "Mah"
  }
]

class NewAnalysis extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef()
    this.state = {
      analysisId: "",
      analysisName: "",
      analysisNameState: "",
      analysisNameError: "",
      systemName: "",
      systemNameState: "",
      systemNameError: "",
      programmingLanguage: "",
      programmingLanguageState: "",
      programmingLanguageError: "",
      programmingLanguages: [],
      release: "",
      releaseState: "",
      releaseError: "",
      domain: "",
      domainState: "",
      domainError: "",
      budget : "",
      budgetState : "",
      budgetError : "",
      organization: "",
      organizationState: "",
      organizationError: "",
      description: "",
      descriptionState: "",
      descriptionError: "",
      uploadedDoc: "",
      fileError: "",
      downloadModalVisible: false,
      groupModalVisible: false,
      groupLevel1: "",
      groupLevel2: "",
      groupLevel3: "",
      groupLevel4: "",
      groupLevel5: "",
      showGroupLevel1: true,
      showGroupLevel2: true,
      showGroupLevel3: true,
      showGroupLevel4: true,
      showGroupLevel5: true,
      analysisId: 0,
      nextFlag:true,
      isLoading: false,
      totalRequirementCount: 0,
      radioButtonValue: 0,
      valueForNewDevelopmentOrEnhancement: 0,
      valueForNewDevelopmentOrEnhancementState: 'success',
      tableData: [
        { id: '', description: '', grouplevelone: '', groupleveltwo: '', grouplevelthree: '', grouplevelfour: '', grouplevelfive: '', isValidDescription: false, isValidId: false, sameIdError: false }
      ],
      dropDownActualValues: [],
      dropDownUniqueListArray: [
        { "Id": 1, "Name": "Unique id" },
        { "Id": 2, "Name": "Requirement Text" },
        { "Id": 3, "Name": "Group Level One" },
        { "Id": 4, "Name": "Group Level Two" },
        { "Id": 5, "Name": "Group Level Three" },
        { "Id": 6, "Name": "Group Level Four" },
        { "Id": 7, "Name": "Group Level Five" }
      ],
      loading: false,
      columns: [],

      showConfigurePopup: false,

      showHeaderGroupLevelOnePopup: false,
      previousHeaderGroupLevelOneValue: 'Group level 1',
      headerGroupLevelOneValue: 'Group level 1',
      headerGroupLevelOneValueState: '',
      headerGroupLevelOneValueError: '',

      showHeaderGroupLevelTwoPopup: false,
      previousHGroupLevelTwoValue: 'Group level 2',
      headerGroupLevelTwoValue: 'Group level 2',
      headerGroupLevelTwoValueState: '',
      headerGroupLevelTwoValueError: '',

      showHeaderGroupLevelThreePopup: false,
      previousHGroupLevelThreeValue: 'Group level 3',
      headerGroupLevelThreeValue: 'Group level 3',
      headerGroupLevelThreeValueState: '',
      headerGroupLevelThreeValueError: '',

      showHeaderGroupLevelFourPopup: false,
      previousHGroupLevelFourValue: 'Group level 4',
      headerGroupLevelFourValue: 'Group level 4',
      headerGroupLevelFourValueState: '',
      headerGroupLevelFourValueError: '',

      showHeaderGroupLevelFivePopup: false,
      previousHGroupLevelFiveValue: 'Group level 5',
      headerGroupLevelFiveValue: 'Group level 5',
      headerGroupLevelFiveValueState: '',
      headerGroupLevelFiveValueError: '',

      dataSourceForGroupLevelOne: [],
      dataSourceForGroupLevelTwo: [],
      dataSourceForGroupLevelThree: [],
      dataSourceForGroupLevelFour: [],
      dataSourceForGroupLevelFive: [],

      showSheetsDropDown: false,
      wholeFileResponse: {},
      sheetsArray: [],
      actualSheetTableColumns: [],
      sheetTableColumns: [],
      sheetTableData: [],
      warningsInfoForSheet: {},
      showJiraPopup:false,
      jiraUrl: "",
      jiraAuthToken:"",
      jiraUsername:"",
      project: undefined,
      issueStatus: [],
      issueType: [],
      projectError:"",
      issueStatusError:"",
      issueTypeError:"",
      projectData:[],
      projectList:[],
      issueTypeList:[],
      issueStatuses:[],
      isJiraLoggedIn: false,
      isMetaData: false,
      jiraUrlError:"", 
      jiraUsernameError:"", 
      jiraAuthTokenError:"",
      jiraUrlErrorMsg:"", 
      jiraUsernameErrorMsg:"", 
      jiraAuthTokenErrorMsg:"",
      issueTableColumns:[],
      actualJiraColumn:[],
      issueList:[]
    };
  }

  setdownloadModalVisible(downloadModalVisible) {
    this.setState({ downloadModalVisible });
  }

  setGroupModalVisible(groupModalVisible) {
    this.setState({ groupModalVisible });
  }

  componentDidMount = async () => {
    try {
      const response = await _languageService.getAllLanguages();
      let languageList = [];
      response.data.map((language, index) => {
        languageList.push({ Id: language.id, Name: language.language_name });
      });
      this.setState({ programmingLanguages: languageList });
    } catch (e) {
      this.setState({ isLoading: false });
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
    }

    // let tempArray = [];
    // let newColumnArray = [];

    // let respColumn = [
    //   { name: "Id", dataIndex: 'colOne' },
    //   { name: "Requirement Text", dataIndex: 'colTwo' },
    //   { name: "Group Level One", dataIndex: 'colThree' },
    //   { name: "Group Level Two", dataIndex: 'colFour' },
    //   { name: "Column One", dataIndex: 'colFive' },
    //   { name: "Column Two", dataIndex: 'colSix' }
    // ]

    // 
    // for (let xx = 0; xx < respColumn.length; xx++) {
    //   
    //   if (xx <= 3) {
    //     tempArray.push(xx + 1)
    //   } else {
    //     tempArray.push(0);
    //   }
    // }
    // 
    // this.setState({
    //   dropDownActualValues: tempArray
    // }, () => {
    //   for (let index = 0; index < respColumn.length; index++) {
    //     
    //     let obj = {
    //       title: <div className="uploadFileTableColumn">
    //         <DropdownList
    //           style={{ width: '33%' }}
    //           value={this.state.dropDownActualValues[index]}
    //           name={`${index + 1}`}
    //           onChange={(event) => { this.onDropDwonTextChange(event, index) }}
    //           placeholder="Select"
    //           className="w-100"
    //           wrapClass="wrap"
    //           isRequired={false}
    //           isDisabled={false}
    //           options={this.state.dropDownUniqueListArray}
    //         />
    //         {respColumn[index].value}
    //       </div>,
    //       dataIndex: respColumn[index].dataIndex,
    //       // width: '30%',
    //     }
    //     
    //     newColumnArray.push(obj)
    //   }
    // })

    this.setState({
      columns: [
        {
          title: <div style={{}} className="d-flex align-items-center">Id
            {/* <Button type='link' className="p-0 ml-2" ghost onClick={() => this.addNewRecord()}>
              <IconMarin iconName="keyWordPlus" fill="rgb(95, 99, 104)" class="icon" />
            </Button> */}
          </div>,
          dataIndex: 'id',
          width: '10%',
          // sorter: true,
          render: (text, record, index) => {
            return (
              <Input
                className={!record.isValidId ? "has-error" : !record.sameIdError ? "has-error" : ""}
                ref={this.textInput}
                type="text"
                name="keyword"
                style={{ height: "30px" ,minWidth:'40px'}}
                value={record.id}
                onBlur={() => { this.onIdBlur(record, index) }}
                onChange={(ev) => this.onIdChange(ev, record, index)} />);
          }
        },
        {
          title: 'Description',
          dataIndex: 'description',
          width: '40%',
          // sorter: true,
          render: (text, record, index) => {
            return (
              <textarea
                className={!record.isValidDescription ? "has-error" : ""}
                rows={"4"}
                cols="36"
                style={{}}
                name={"description"}
                value={record.description}
                maxLength={1024}
                onChange={(ev) => this.onDescriptionChange(ev, record, index)}
              // value={props.value}
              // placeholder={props.placeholder}
              // className={
              //   props.errorState === "error" ? "has-error form-control" : props.className
              // }
              />
              // <Input
              //   // className={!record.is_validKeyword ? "has-error" : ""}
              //   type="text"
              //   name="keyword"
              //   style={{ height: "30px" }}
              //   // value={record.keyword_name}
              //   onChange={(ev) => this.onDescriptionChange(ev, record.id)} />
            );
          }
        },
        {
          title: <div style={{}} className="d-flex align-items-center">Group Level 1
          <Button type='link' className="p-0 ml-2" ghost onClick={() => this.editGroupLevelName("showHeaderGroupLevelOnePopup")}>
              <IconMarin iconName="edit" fill="rgb(95, 99, 104)" class="icon" />
            </Button>
          </div>,
          dataIndex: 'grouplevelone',
          width: '15%',
          // sorter: true,
          render: (text, record, index) => {
            return (
              <AutoComplete
                dataSource={this.state.dataSourceForGroupLevelOne}
                // style={{ width: 140 }}
                onBlur={(ev) => this.onBlurForGroupLevelOne(ev, record, index)}
                onSelect={(ev) => this.onSelectForGroupLevelOne(ev, record, index)}
                onSearch={(ev) => this.handleSearchGroupLevelOne(ev, record, index)}
              />
              // <Input
              //   // className={!record.is_validKeyword ? "has-error" : ""}
              //   type="text"
              //   name="keyword"
              //   maxLength={50}
              //   style={{ height: "30px" }}
              //   value={record.grouplevelone}
              //   onChange={(ev) => this.onGroupLevelOneChange(ev, record, index)} />
              // <AutoComplete
              //   value={record.grouplevelone}
              //   options={this.state.autoCompleteOptions}
              //   style={{ width: 200 }}
              //   // onSelect={onSelect}
              //   // onSearch={onSearch}
              //   onChange={(ev) => this.onGroupLevelOneChange(ev, record, index)}
              // />
              // <AutoComplete
              //   // style={{
              //   //   width: 200,
              //   // }}
              //   alue={record.grouplevelone}
              //   onChange={(ev) => this.onGroupLevelOneChange(ev, record, index)}
              //   options={this.state.autoCompleteOptions}
              //   placeholder="try to type `b`"
              //   filterOption={(inputValue, option) => 
              //     option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              //   }
              // />
            );
          }
        },
        {
          title: <div style={{}} className="d-flex align-items-center">Group Level 2
          <Button type='link' className="p-0 ml-2" ghost onClick={() => this.editGroupLevelName("showHeaderGroupLevelTwoPopup")}>
              <IconMarin iconName="edit" fill="rgb(95, 99, 104)" class="icon" />
            </Button>
          </div>,
          dataIndex: 'groupleveltwo',
          width: '15%',
          // sorter: true,
          render: (text, record, index) => {
            return (
              <AutoComplete
                dataSource={this.state.dataSourceForGroupLevelTwo}
                // style={{ width: 140 }}
                onBlur={(ev) => this.onBlurForGroupLevelTwo(ev, record, index)}
                onSelect={(ev) => this.onSelectForGroupLevelTwo(ev, record, index)}
                onSearch={(ev) => this.handleSearchGroupLevelTwo(ev, record, index)}
              />
            );
          }
        },
        {
          title: <div style={{}} className="d-flex align-items-center">Group Level 3
          <Button type='link' className="p-0 ml-2" ghost onClick={() => this.editGroupLevelName("showHeaderGroupLevelThreePopup")}>
              <IconMarin iconName="edit" fill="rgb(95, 99, 104)" class="icon" />
            </Button>
          </div>,
          dataIndex: 'grouplevelthree',
          width: '15%',
          // sorter: true,
          render: (text, record, index) => {
            return (
              <AutoComplete
                dataSource={this.state.dataSourceForGroupLevelThree}
                // style={{ width: 140 }}
                onBlur={(ev) => this.onBlurForGroupLevelThree(ev, record, index)}
                onSelect={(ev) => this.onSelectForGroupLevelThree(ev, record, index)}
                onSearch={(ev) => this.handleSearchGroupLevelThree(ev, record, index)}
              />
            );
          }
        },
        {
          title: <div style={{}} className="d-flex align-items-center">Group Level 4
          <Button type='link' className="p-0 ml-2" ghost onClick={() => this.editGroupLevelName("showHeaderGroupLevelFourPopup")}>
              <IconMarin iconName="edit" fill="rgb(95, 99, 104)" class="icon" />
            </Button>
          </div>,
          dataIndex: 'grouplevelfour',
          width: '15%',
          // sorter: true,
          render: (text, record, index) => {
            return (
              <AutoComplete
                dataSource={this.state.dataSourceForGroupLevelFour}
                // style={{ width: 140 }}
                onBlur={(ev) => this.onBlurForGroupLevelFour(ev, record, index)}
                onSelect={(ev) => this.onSelectForGroupLevelFour(ev, record, index)}
                onSearch={(ev) => this.handleSearchGroupLevelFour(ev, record, index)}
              />
            );
          }
        },
        {
          title: <div style={{}} className="d-flex align-items-center">Group Level 5
          <Button type='link' className="p-0 ml-2" ghost onClick={() => this.editGroupLevelName("showHeaderGroupLevelFivePopup")}>
              <IconMarin iconName="edit" fill="rgb(95, 99, 104)" class="icon" />
            </Button>
          </div>,
          dataIndex: 'grouplevelfive',
          width: '15%',
          // sorter: true,
          render: (text, record, index) => {
            return (
              <AutoComplete
                dataSource={this.state.dataSourceForGroupLevelFive}
                // style={{ width: 140 }}
                onBlur={(ev) => this.onBlurForGroupLevelFive(ev, record, index)}
                onSelect={(ev) => this.onSelectForGroupLevelFive(ev, record, index)}
                onSearch={(ev) => this.handleSearchGroupLevelFive(ev, record, index)}
              />
            );
          }
        },
        {
          title: 'Actions',
          dataIndex: 'operation',
          align: 'center',
          width: '10%',
          render: (text, record, index) => {
            return (<div className="d-flex" style={{ justifyContent: 'center' }}>
              <Button type="link" className="p-0" ghost onClick={() => this.deleteRecord(record.id, index)}>
                <IconMarin iconName="delete" fill="rgb(230, 54, 52)" class="icon" />
              </Button>
            </div>);
          }
        }
      ],
      // sheetTableColumns: newColumnArray,
      // sheetTableData: [
      //   { 'colOne': 'Test', 'colTwo': 'Test', 'colThree': 'Test', 'colFour': 'Test', 'colFive': 'Test', 'colSix': 'Test' }
      // ]
    })
  }

  focus() {
    this.textInput.current.focus()
  }

  onIdBlur = (record, index) => {

  }

  onIdChange = (ev, record, index) => {
    record.id = ev.target.value
    if (ev.target.value.trim() != '' && record.id != '') {
      record.isValidId = true;
      record.sameIdError = true;
    } else {
      record.isValidId = false;
      record.sameIdError = true;
    }
    let updatedTableData = this.state.tableData.map((item, idx) =>
      idx === index ? record : item
    )

    this.setState({
      tableData: updatedTableData,
    }, () => {
      console.log('UpdatedData: ', this.state.tableData);

    })
  }

  onDescriptionChange = (ev, record, index) => {
    record.description = ev.target.value
    if (ev.target.value.trim() != '' && record.description != '') {
      record.isValidDescription = true
    } else {
      record.isValidDescription = false
    }
    let updatedTableData = this.state.tableData.map((item, idx) =>
      idx === index ? record : item
    )
    this.setState({
      tableData: updatedTableData,
    }, () => {
      console.log('UpdatedData: ', this.state.tableData);

    })
  }

  onBlurForGroupLevelOne = (ev, record, index) => {
    let tempSuggestionArray = [...this.state.dataSourceForGroupLevelOne];
    let tableData = [...this.state.tableData];
    if (tableData && tableData.length > 0) {
      for (let index = 0; index < tableData.length; index++) {
        const element = tableData[index];
        if (element && element.grouplevelone && element.grouplevelone != "") {
          if (!tempSuggestionArray.includes(element.grouplevelone))
            tempSuggestionArray.push(element.grouplevelone);
        }
      }
    }

    this.setState({
      dataSourceForGroupLevelOne: tempSuggestionArray
    })

  }

  handleSearchGroupLevelOne = (ev, record, index) => {
    console.log('onSelect', ev);
    console.log('onSelect', record);
    console.log('onSelect', index);

    record.grouplevelone = ev;
    // record.grouplevelone = ev;
    let updatedTableData = this.state.tableData.map((item, idx) =>
      idx === index ? record : item
    )

    this.setState({
      tableData: updatedTableData
    }, () => {
      console.log('UpdatedData: ', this.state.tableData);
    })
  }

  onSelectForGroupLevelOne = (ev, record, index) => {
    console.log('onSelect', ev);
    console.log('onSelect', record);
    console.log('onSelect', index);

    record.grouplevelone = ev;
    // record.grouplevelone = ev;
    let updatedTableData = this.state.tableData.map((item, idx) =>
      idx === index ? record : item
    )

    this.setState({
      tableData: updatedTableData
    }, () => {
      console.log('UpdatedData: ', this.state.tableData);
    })
  }

  onGroupLevelOneChange = (ev, record, index) => {
    record.grouplevelone = ev.target.value;
    // record.grouplevelone = ev;
    let updatedTableData = this.state.tableData.map((item, idx) =>
      idx === index ? record : item
    )
    this.setState({
      tableData: updatedTableData,
    }, () => {
      console.log('UpdatedData: ', this.state.tableData);
    })
  }

  onBlurForGroupLevelTwo = (ev, record, index) => {
    let tempSuggestionArray = [...this.state.dataSourceForGroupLevelTwo];
    let tableData = [...this.state.tableData];
    if (tableData && tableData.length > 0) {
      for (let index = 0; index < tableData.length; index++) {
        const element = tableData[index];
        if (element && element.grouplevelone && element.grouplevelone != "") {
          if (!tempSuggestionArray.includes(element.groupleveltwo))
            tempSuggestionArray.push(element.groupleveltwo);
        }
      }
    }
    this.setState({
      dataSourceForGroupLevelTwo: tempSuggestionArray
    })

  }

  handleSearchGroupLevelTwo = (ev, record, index) => {
    record.groupleveltwo = ev;
    let updatedTableData = this.state.tableData.map((item, idx) =>
      idx === index ? record : item
    )

    this.setState({
      tableData: updatedTableData
    }, () => {
      console.log('UpdatedData: ', this.state.tableData);
    })
  }

  onSelectForGroupLevelTwo = (ev, record, index) => {
    record.groupleveltwo = ev;
    let updatedTableData = this.state.tableData.map((item, idx) =>
      idx === index ? record : item
    )

    this.setState({
      tableData: updatedTableData
    }, () => {
      console.log('UpdatedData: ', this.state.tableData);
    })
  }

  onBlurForGroupLevelThree = (ev, record, index) => {
    let tempSuggestionArray = [...this.state.dataSourceForGroupLevelThree];
    let tableData = [...this.state.tableData];
    if (tableData && tableData.length > 0) {
      for (let index = 0; index < tableData.length; index++) {
        const element = tableData[index];
        if (element && element.grouplevelone && element.grouplevelone != ""
        && element.groupleveltwo && element.groupleveltwo != "") {
          if (!tempSuggestionArray.includes(element.grouplevelthree))
            tempSuggestionArray.push(element.grouplevelthree);
        }
      }
    }
    this.setState({
      dataSourceForGroupLevelThree: tempSuggestionArray
    })

  }

  handleSearchGroupLevelThree = (ev, record, index) => {
    record.grouplevelthree = ev;
    let updatedTableData = this.state.tableData.map((item, idx) =>
      idx === index ? record : item
    )

    this.setState({
      tableData: updatedTableData
    }, () => {
      console.log('UpdatedData: ', this.state.tableData);
    })
  }

  onSelectForGroupLevelThree = (ev, record, index) => {
    record.grouplevelthree = ev;
    let updatedTableData = this.state.tableData.map((item, idx) =>
      idx === index ? record : item
    )

    this.setState({
      tableData: updatedTableData
    }, () => {
      console.log('UpdatedData: ', this.state.tableData);
    })
  }

  onBlurForGroupLevelFour = (ev, record, index) => {
    let tempSuggestionArray = [...this.state.dataSourceForGroupLevelFour];
    let tableData = [...this.state.tableData];
    if (tableData && tableData.length > 0) {
      for (let index = 0; index < tableData.length; index++) {
        const element = tableData[index];
        if (element && element.grouplevelone && element.grouplevelone != ""
        && element.groupleveltwo && element.groupleveltwo != ""
        && element.grouplevelthree && element.grouplevelthree != "") {
          if (!tempSuggestionArray.includes(element.grouplevelfour))
            tempSuggestionArray.push(element.grouplevelfour);
        }
      }
    }
    this.setState({
      dataSourceForGroupLevelFour: tempSuggestionArray
    })

  }

  handleSearchGroupLevelFour = (ev, record, index) => {
    record.grouplevelfour = ev;
    let updatedTableData = this.state.tableData.map((item, idx) =>
      idx === index ? record : item
    )

    this.setState({
      tableData: updatedTableData
    }, () => {
      console.log('UpdatedData: ', this.state.tableData);
    })
  }

  onSelectForGroupLevelFour = (ev, record, index) => {
    record.grouplevelfour = ev;
    let updatedTableData = this.state.tableData.map((item, idx) =>
      idx === index ? record : item
    )

    this.setState({
      tableData: updatedTableData
    }, () => {
      console.log('UpdatedData: ', this.state.tableData);
    })
  }

  onBlurForGroupLevelFive = (ev, record, index) => {
    let tempSuggestionArray = [...this.state.dataSourceForGroupLevelFive];
    let tableData = [...this.state.tableData];
    if (tableData && tableData.length > 0) {
      for (let index = 0; index < tableData.length; index++) {
        const element = tableData[index];
        if (element && element.grouplevelone && element.grouplevelone != ""
        && element.groupleveltwo && element.groupleveltwo != ""
        && element.grouplevelthree && element.grouplevelthree != ""
        && element.grouplevelfour && element.grouplevelfour != "") {
          if (!tempSuggestionArray.includes(element.grouplevelfive))
            tempSuggestionArray.push(element.grouplevelfive);
        }
      }
    }
    this.setState({
      dataSourceForGroupLevelFive: tempSuggestionArray
    })

  }

  handleSearchGroupLevelFive = (ev, record, index) => {
    record.grouplevelfive = ev;
    let updatedTableData = this.state.tableData.map((item, idx) =>
      idx === index ? record : item
    )

    this.setState({
      tableData: updatedTableData
    }, () => {
      console.log('UpdatedData: ', this.state.tableData);
    })
  }

  onSelectForGroupLevelFive = (ev, record, index) => {
    record.grouplevelfive = ev;
    let updatedTableData = this.state.tableData.map((item, idx) =>
      idx === index ? record : item
    )

    this.setState({
      tableData: updatedTableData
    }, () => {
      console.log('UpdatedData: ', this.state.tableData);
    })
  }

  addNewRecord = () => {
    let tempData = [...this.state.tableData];
    let checkError = false;


    b: for (let index = 0; index < tempData.length; index++) {
      const element = tempData[index];
      if (!element.isValidId || !element.isValidDescription) {
        checkError = true;
        break b;
      }
    }


    if (checkError) {
      _notification.showMessage('error', ANALYSIS_MANUAL_ENTRY);
    } else {
      // if (!checkId) {
      let newRowData = { id: '', description: '', grouplevelone: '', groupleveltwo: '', grouplevelthree: '', grouplevelfour: '', grouplevelfive: '' }
      let newData = [...this.state.tableData, newRowData];
      this.setState({
        tableData: newData,
      }, () => {
        setTimeout(() => {
          this.focus()
        }, 500)
      });
      // } else {
      //   this.setState({
      //     tableData: tempData
      //   }, () => {
      //     _notification.showMessage('error', DUBLICATE_REQUIRMENT_NO);
      //   })
      // }
    }
  }

  deleteRecord = (recordId, index) => {
    let tempData = [...this.state.tableData];
    let tempLenght = tempData.length;
    if (tempLenght)
      tempData.splice(index, 1);
    this.setState({
      tableData: tempData,
    });
  }

  onTextChange = ({ target: { name, value } }) => {
    switch (name) {
      case "analysisName":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyLength(value, 50)) {
            this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
          }
          else {
            this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
          }
        } else {
          this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter analysis name" });
        }
        break;

        case "budget" :
          var curValue =value.replace(/[^0-9]*/g,'');
          if(_validator.verifyBlank(curValue)){
            if(value >= 0 && value <= 9){
              this.setState({[name + "State"]: "success", [name + "Error"]: ""});
            }
          }
          else{
            this.setState({[name + "State"]: "error", [name + "Error"]: "" });
          }
        break;

      case "systemName":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyLength(value, 50)) {
            this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
          }
          else {
            this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
          }
        } else {
          this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter system name" });
        }
        break;
      case "programmingLanguage":
        if (_validator.verifyBlank(value)) {
          this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
        } else {
          this.setState({ [name + "State"]: "error", [name + "Error"]: "Please select programming language" });
        }
        break;
      case "description":
        if (value.trim() != "" && !_validator.verifyLength(value, 1000)) {
          this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 1000 characters allowed" });
        } else {
          this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
        }
        break;
      case "headerGroupLevelOneValue":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyLength(value, 50)) {
            this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
          } else {
            this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
          }
        } else {
          this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter Group Level 1" });
        }
        break;
      case "headerGroupLevelTwoValue":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyLength(value, 50)) {
            this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
          } else {
            this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
          }
        } else {
          this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter Group Level 2" });
        }
        break;
      case "headerGroupLevelThreeValue":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyLength(value, 50)) {
            this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
          } else {
            this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
          }
        } else {
          this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter Group Level 2" });
        }
        break;
      case "headerGroupLevelFourValue":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyLength(value, 50)) {
            this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
          } else {
            this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
          }
        } else {
          this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter Group Level 2" });
        }
        break;
      case "headerGroupLevelFiveValue":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyLength(value, 50)) {
            this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
          } else {
            this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
          }
        } else {
          this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter Group Level 2" });
        }
        break;
      case "valueForNewDevelopmentOrEnhancement":
        if (_validator.verifyBlank(value)) {
          this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
        } else {
          this.setState({ [name + "State"]: "error", [name + "Error"]: "Please select Type" });
        }
        break;
      default:
        if (_validator.verifyLength(value, 50)) {
          this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
        }
        else {
          this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
        }
    }
    
    this.setState({ [name]: name == "budget" ? curValue:value });
  }

  onFileDrop = (file) => {
    this.setState({ uploadedDoc: file, fileError: '' });
  }

  onCancel = () => {
    this.props.history.push(routes.home.basePath);
  }

  onConfigure = () => {
    this.setState({
      showConfigurePopup: true
    })
  }

  setShowonfigurePopup = (showConfigurePopup) => {
    this.setState({
      showConfigurePopup: showConfigurePopup,
    })
  }

  redirectToRequirement = () => {
    this.props.history.push(routes.ViewRequirements.basePath + "/" + this.state.analysisId);
  }

  redirectToHome = () => {
    this.setState({ groupModalVisible: false });
    this.props.history.push(routes.home.basePath);
  }

  // validateAnalysisDetails=()=>{
  //   const { analysisNameState, systemNameState, programmingLanguageState, valueForNewDevelopmentOrEnhancementState } = this.state;
  //   if (analysisNameState === "success" && systemNameState === "success" && programmingLanguageState === "success" && valueForNewDevelopmentOrEnhancementState === "success") {
  //     this.setState({ radioButtonValue: 2 });
  //   }else{
  //     window.scroll(0, 0);
  //     _notification.showMessage('error', ALL_FIELD_REQUIREMENT);
  //     if (analysisNameState === "") {
  //       this.setState({
  //         analysisNameState: "error",
  //         analysisNameError: "Please enter Analysis Name",
  //         radioButtonValue:0
  //       });
  //     }
  //     if (systemNameState === "") {
  //       this.setState({
  //         systemNameState: "error",
  //         systemNameError: "Please enter System Name",
  //         radioButtonValue:0
  //       });
  //     }
  //     if (programmingLanguageState === "") {
  //       this.setState({
  //         programmingLanguageState: "error",
  //         programmingLanguageError: "Please select Programming Language",
  //         radioButtonValue:0
  //       });
  //     }
  //     if (valueForNewDevelopmentOrEnhancementState === "") {
  //       this.setState({
  //         valueForNewDevelopmentOrEnhancementState: "error",
  //         valueForNewDevelopmentOrEnhancementError: "Please select Type",
  //         radioButtonValue:0
  //       });
  //     }
  //   }
  // }

  onSubmit = async () => {
    
    const { analysisNameState, systemNameState, programmingLanguageState, valueForNewDevelopmentOrEnhancementState,
      analysisName, systemName, organization, budget, domain, programmingLanguage,
      release, description, uploadedDoc } = this.state;
    if (this.state.radioButtonValue !== 2 && analysisNameState === "success" && systemNameState === "success" && programmingLanguageState === "success" && valueForNewDevelopmentOrEnhancementState === "success") {
      try {
        if (this.state.radioButtonValue === 0) {
          if (uploadedDoc != "") {
            if (uploadedDoc.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
              this.addAnalysis(analysisName, budget, systemName, organization, domain, programmingLanguage, release, description, uploadedDoc);
            } else {
              this.addAnalysisToConfigureFile(analysisName,systemName, organization, domain, programmingLanguage, release, description, uploadedDoc);
            }
          } else {
            this.setState({
              fileError: "Please select file"
            });
          }
        } else if (this.state.radioButtonValue === 1) {
          let tempData = [...this.state.tableData];
          let checkError = false;
          let checkId = false;

          a: for (let index = 0; index < tempData.length; index++) {
            const element = tempData[index];
            if (!element.isValidId || !element.isValidDescription) {
              checkError = true;
              break a;
            }
          }

          for (let index = 0; index < tempData.length; index++) {
            for (let innerIdx = 0; innerIdx < index; innerIdx++) {
              if (tempData[innerIdx].id === tempData[index].id) {
                checkId = true;
                tempData[index].sameIdError = false;
              }
            }
          }

          if (checkError) {
            _notification.showMessage('error', ANALYSIS_MANUAL_ENTRY);
          } else {
            if (!checkId) {
              this.addAnalysis(analysisName,budget, systemName, organization, domain, budget, programmingLanguage, release, description, uploadedDoc);
            } else {
              this.setState({
                tableData: tempData
              }, () => {
                _notification.showMessage('error', DUBLICATE_REQUIRMENT_NO);
              })
            }
          }
        }
      } catch (e) {
        // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
      }
    }else if(this.state.radioButtonValue === 2 && analysisNameState === "success" && programmingLanguageState === "success" && valueForNewDevelopmentOrEnhancementState === "success"){
      this.setState({showJiraPopup:true});
    }else {
      window.scroll(0, 0);
      _notification.showMessage('error', ALL_FIELD_REQUIREMENT);
      if (analysisNameState === "") {
        this.setState({
          analysisNameState: "error",
          analysisNameError: "Please enter Analysis Name"
        });
      }
      if (this.state.radioButtonValue !== 2 && systemNameState === "") {
        this.setState({
          systemNameState: "error",
          systemNameError: "Please enter System Name"
        });
      }
      if (programmingLanguageState === "") {
        this.setState({
          programmingLanguageState: "error",
          programmingLanguageError: "Please select Programming Language"
        });
      }
      if (valueForNewDevelopmentOrEnhancementState === "") {
        this.setState({
          valueForNewDevelopmentOrEnhancementState: "error",
          valueForNewDevelopmentOrEnhancementError: "Please select Type"
        });
      }
      if (this.state.radioButtonValue === 0) {
        if (uploadedDoc == "") {
          this.setState({
            fileError: "Please select file"
          });
        }
      }
    }
  }

  onConfigureTableNextClick = () => {
    let sheetName = '';
    let temp = [...this.state.sheetsArray];
    if (temp && temp.length > 0) {
      for (let index = 0; index < temp.length; index++) {
        const elementToGetSheetName = temp[index];
        if (elementToGetSheetName.Id === parseInt(this.state.dropDownForSheet)) {
          sheetName = elementToGetSheetName.Name
        }
      }
    }

    let dropDwonErrorForUniqueId = false;
    let dropDwonErrorForRequirementText = false;
    let groupLevelOneFlag = false;
    let groupLevelTwoFlag = false;
    let groupLevelThreeFlag = false;
    let groupLevelFourFlag = false;
    let groupLevelFiveFlag = false;
    let uniqueIndex = -1;
    let reqTextIndex = -1;
    let glOneIndex = -1;
    let glTwoIndex = -1, glThreeIndex = -1, glFourIndex = -1, glFiveIndex = -1;

    let tempDropDownValues = [...this.state.dropDownActualValues];
    
    if (tempDropDownValues && tempDropDownValues.length > 0) {
      for (let index = 0; index < tempDropDownValues.length; index++) {
        const elementToCheckIdForUniqueIdReqTextGroupLevelOneGroupLevelTwo = tempDropDownValues[index];
        if (elementToCheckIdForUniqueIdReqTextGroupLevelOneGroupLevelTwo === 1) {
          uniqueIndex = index;
        }
        if (elementToCheckIdForUniqueIdReqTextGroupLevelOneGroupLevelTwo === 2) {
          reqTextIndex = index;
        }
        if (elementToCheckIdForUniqueIdReqTextGroupLevelOneGroupLevelTwo === 3) {
          glOneIndex = index;
        }
        if (elementToCheckIdForUniqueIdReqTextGroupLevelOneGroupLevelTwo === 4) {
          glTwoIndex = index;
        }
        if (elementToCheckIdForUniqueIdReqTextGroupLevelOneGroupLevelTwo === 5) {
          glThreeIndex = index;
        }
        if (elementToCheckIdForUniqueIdReqTextGroupLevelOneGroupLevelTwo === 6) {
          glFourIndex = index;
        }
        if (elementToCheckIdForUniqueIdReqTextGroupLevelOneGroupLevelTwo === 7) {
          glFiveIndex = index;
        }
      }
      for (let index = 0; index < tempDropDownValues.length; index++) {
        const elementToCheckIdForUniqueIdReqTextPresentOrNot = tempDropDownValues[index];
        if (elementToCheckIdForUniqueIdReqTextPresentOrNot === 1) {
          dropDwonErrorForUniqueId = true;
        }
        if (elementToCheckIdForUniqueIdReqTextPresentOrNot === 2) {
          dropDwonErrorForRequirementText = true;
        }
        if (elementToCheckIdForUniqueIdReqTextPresentOrNot === 3) {
          groupLevelOneFlag = true;
        }
        if (elementToCheckIdForUniqueIdReqTextPresentOrNot === 4) {
          groupLevelTwoFlag = true;
        }
        if (elementToCheckIdForUniqueIdReqTextPresentOrNot === 5) {
          groupLevelThreeFlag = true;
        }
        if (elementToCheckIdForUniqueIdReqTextPresentOrNot === 6) {
          groupLevelFourFlag = true;
        }
        if (elementToCheckIdForUniqueIdReqTextPresentOrNot === 7) {
          groupLevelFiveFlag = true;
        }
      }
    }

    if (dropDwonErrorForUniqueId && dropDwonErrorForRequirementText) {
      var formData = new FormData();
      let finalObj = {};
      formData.append("analysis_name", this.state.analysisName);
      formData.append("budget", this.state.budget)
      formData.append("system_name", this.state.systemName);
      formData.append("organization", this.state.organization.trim());
      formData.append("domain", this.state.domain.trim());
      formData.append("language", parseInt(this.state.programmingLanguage));
      formData.append("release", this.state.release.trim());
      formData.append("description", this.state.description.trim());
      formData.append("isManual", 1);
      formData.append("group_level1_text", this.state.headerGroupLevelOneValue);
      formData.append("group_level2_text", this.state.headerGroupLevelTwoValue);
//new params
      formData.append("group_level3_text", this.state.headerGroupLevelThreeValue);
      formData.append("group_level4_text", this.state.headerGroupLevelFourValue);
      formData.append("group_level5_text", this.state.headerGroupLevelFiveValue);
      
      formData.append("requirement_file", this.state.uploadedDoc);

      formData.append("sheet_name", sheetName);
      formData.append("unique_id_index", uniqueIndex);
      formData.append("requirement_text_index", reqTextIndex);
      formData.append("group_level_one_index", glOneIndex);
      formData.append("group_level_two_index", glTwoIndex);
      //new params
      formData.append("group_level_three_index", glThreeIndex);
      formData.append("group_level_four_index", glFourIndex);
      formData.append("group_level_five_index", glFiveIndex);
    
      formData.append("status", this.state.valueForNewDevelopmentOrEnhancement);

      this.setState({
        isLoading: true
      }, async () => {
        let response = '';
        response = await _analysisService.addAnalysis(formData);

        this.setState({
          isLoading: false
        }, () => {
          if (response.data.isSuccess) {
            this.setState({
              analysisId: response.data.analysis_id
            }, () => {

              let tempArray = [...this.state.dropDownActualValues];

              let indexToFindTheGroupLevelOneNameFromResponseColumnArray = '';
              let indexToFindTheGroupLevelTwoNameFromResponseColumnArray = '';
              let indexToFindTheGroupLevelThreeNameFromResponseColumnArray = '';
              let indexToFindTheGroupLevelFourNameFromResponseColumnArray = '';
              let indexToFindTheGroupLevelFiveNameFromResponseColumnArray = '';
            
              for (let z = 0; z < tempArray.length; z++) {
                const xxx = tempArray[z];
                if (xxx === 3) {
                  indexToFindTheGroupLevelOneNameFromResponseColumnArray = z;
                }
              // }
              // for (let y = 0; y < tempArray.length; y++) {
              //   const yyy = tempArray[y];
                if (xxx === 4) {
                  indexToFindTheGroupLevelTwoNameFromResponseColumnArray = z;
                }

                if (xxx === 5) {
                  indexToFindTheGroupLevelThreeNameFromResponseColumnArray = z;
                }

                if (xxx === 6) {
                  indexToFindTheGroupLevelFourNameFromResponseColumnArray = z;
                }

                if (xxx === 7) {
                  indexToFindTheGroupLevelFiveNameFromResponseColumnArray = z;
                }
              }

              let respColumn = [...this.state.actualSheetTableColumns];
              let valueForGroupLevelOneToStoreInLocalStorage = '';
              let valueForGroupLevelTwoToStoreInLocalStorage = '';
              let valueForGroupLevelThreeToStoreInLocalStorage = '';
              let valueForGroupLevelFourToStoreInLocalStorage = '';
              let valueForGroupLevelFiveToStoreInLocalStorage = '';
              
              for (let respColumnIndex = 0; respColumnIndex < respColumn.length; respColumnIndex++) {
                const respColumnElement = respColumn[respColumnIndex];
                if (parseInt(indexToFindTheGroupLevelOneNameFromResponseColumnArray) === respColumnElement.id) {
                  valueForGroupLevelOneToStoreInLocalStorage = respColumnElement.value;
                  // break;
                }
              // }
              // for (let respColumnIndex = 0; respColumnIndex < respColumn.length; respColumnIndex++) {
              //   const respColumnElement = respColumn[respColumnIndex];
                if (parseInt(indexToFindTheGroupLevelTwoNameFromResponseColumnArray) === respColumnElement.id) {
                  valueForGroupLevelTwoToStoreInLocalStorage = respColumnElement.value;
                  // break;
                }
                if (parseInt(indexToFindTheGroupLevelThreeNameFromResponseColumnArray) === respColumnElement.id) {
                  valueForGroupLevelThreeToStoreInLocalStorage = respColumnElement.value;
                }
                if (parseInt(indexToFindTheGroupLevelFourNameFromResponseColumnArray) === respColumnElement.id) {
                  valueForGroupLevelFourToStoreInLocalStorage = respColumnElement.value;
                }
                if (parseInt(indexToFindTheGroupLevelFiveNameFromResponseColumnArray) === respColumnElement.id) {
                  valueForGroupLevelFiveToStoreInLocalStorage = respColumnElement.value;
                }
              }

              if (valueForGroupLevelOneToStoreInLocalStorage)
                localStorage[GROUP_LEVEL_1_DISPLAY_TEXT] = valueForGroupLevelOneToStoreInLocalStorage;
              if (valueForGroupLevelTwoToStoreInLocalStorage)
                localStorage[GROUP_LEVEL_2_DISPLAY_TEXT] = valueForGroupLevelTwoToStoreInLocalStorage;
              if (valueForGroupLevelThreeToStoreInLocalStorage)
                localStorage[GROUP_LEVEL_3_DISPLAY_TEXT] = valueForGroupLevelThreeToStoreInLocalStorage;
              if (valueForGroupLevelFourToStoreInLocalStorage)
                localStorage[GROUP_LEVEL_4_DISPLAY_TEXT] = valueForGroupLevelFourToStoreInLocalStorage;
              if (valueForGroupLevelFiveToStoreInLocalStorage)
                localStorage[GROUP_LEVEL_5_DISPLAY_TEXT] = valueForGroupLevelFiveToStoreInLocalStorage;


              localStorage[SHOW_GROUP_LEVEL_1] = groupLevelOneFlag;
              localStorage[SHOW_GROUP_LEVEL_2] = groupLevelTwoFlag;
              localStorage[SHOW_GROUP_LEVEL_3] = groupLevelThreeFlag;
              localStorage[SHOW_GROUP_LEVEL_4] = groupLevelFourFlag;
              localStorage[SHOW_GROUP_LEVEL_5] = groupLevelFiveFlag;
              localStorage[GROUP_LEVEL_1_DISPLAY_TEXT] = valueForGroupLevelOneToStoreInLocalStorage ? valueForGroupLevelOneToStoreInLocalStorage : 'Group_Level1';
              localStorage[GROUP_LEVEL_2_DISPLAY_TEXT] = valueForGroupLevelTwoToStoreInLocalStorage ? valueForGroupLevelTwoToStoreInLocalStorage : 'Group_Level2';
              localStorage[GROUP_LEVEL_3_DISPLAY_TEXT] = valueForGroupLevelThreeToStoreInLocalStorage ? valueForGroupLevelThreeToStoreInLocalStorage : 'Group_Level3';
              localStorage[GROUP_LEVEL_4_DISPLAY_TEXT] = valueForGroupLevelFourToStoreInLocalStorage ? valueForGroupLevelFourToStoreInLocalStorage : 'Group_Level4';
              localStorage[GROUP_LEVEL_5_DISPLAY_TEXT] = valueForGroupLevelFiveToStoreInLocalStorage ? valueForGroupLevelFiveToStoreInLocalStorage : 'Group_Level5';
    
              this.props.history.push(routes.ViewRequirements.basePath + "/" + response.data.analysis_id);
            });
          }
          else {
            _notification.showMessage('error', response.data.errorMessage);
          }
        })
      })
    } else {
      _notification.showMessage('error', SHEET_DROP_DOWN_ERROR);
    }
  }

  onSheetsDropDownTextChange = ({ target: { name, value } }) => {
    switch (name) {
      case "dropDownForSheet":
        if (value) {
          let tempSheetsArray = [...this.state.sheetsArray];
          let wholeResponse = this.state.wholeFileResponse;

          let currentSelectSheetObj = {};
          if (tempSheetsArray && tempSheetsArray.length > 0) {
            for (let index = 0; index < tempSheetsArray.length; index++) {
              const element = tempSheetsArray[index];
              if (element.Id === parseInt(value)) {
                currentSelectSheetObj = element;
                break;
              }
            }
          }

          let cureentSheetData = wholeResponse.data.sheets_data[currentSelectSheetObj.Id]

          let tempArray = [];
          let newColumnArray = [];

          let respColumn = cureentSheetData.columns;
          let warningsInfoForSheet = cureentSheetData.warnings;
          let respData = cureentSheetData.columns_data;

          for (let xx = 0; xx < respColumn.length; xx++) {
            if (xx <= 6) {
              tempArray.push(xx + 1)
            } else {
              tempArray.push(0);
            }
          }

          this.setState({
            dropDownActualValues: tempArray
          }, () => {
            for (let index = 0; index < respColumn.length; index++) {
              let obj = {
                title: <div className="uploadFileTableColumn">
                  <DropdownList
                    style={{ width: '33%' }}
                    value={this.state.dropDownActualValues[index]}
                    name={`${index + 1}`}
                    onChange={(event) => { this.onDropDwonTextChange(event, index) }}
                    placeholder="Select"
                    className="w-100"
                    wrapClass="wrap"
                    isRequired={false}
                    isDisabled={false}
                    options={this.state.dropDownUniqueListArray}
                  />
                  {respColumn[index].value}
                </div>,
                dataIndex: respColumn[index].column,
                // width: '30%',
              }

              newColumnArray.push(obj)
            }
            this.setState({
              sheetTableColumns: newColumnArray,
              sheetTableData: respData,
              warningsInfoForSheet: warningsInfoForSheet,
              nextFlag:cureentSheetData.Nextflag
            })
          })

        }
        break;
      default:
        break;
    }
    this.setState({ [name]: value });
  }

  onDropDwonTextChange = (event, index) => {
    let tempArray = [...this.state.dropDownActualValues];
    for (let idx = 0; idx < tempArray.length; idx++) {
      const element = tempArray[idx];
      if (element === parseInt(event.target.value)) {
        tempArray[idx] = 0;
      }
    }
    tempArray[index] = event.target.value === "" ? 0 : parseInt(event.target.value);

    let newColumnArray = [];

    let respColumn = [...this.state.actualSheetTableColumns];
    let respData = [...this.state.sheetTableData];

    for (let index = 0; index < respColumn.length; index++) {
      let obj = {
        title: <div className="uploadFileTableColumn">
          <DropdownList
            style={{ width: '33%' }}
            value={tempArray[index]}
            name={`${index + 1}`}
            onChange={(event) => { this.onDropDwonTextChange(event, index) }}
            placeholder="Select"
            className="w-100"
            wrapClass="wrap"
            isRequired={false}
            isDisabled={false}
            options={this.state.dropDownUniqueListArray}
          />
          {respColumn[index].value}
        </div>,
        dataIndex: respColumn[index].column,
        // width: '30%',
      }
      newColumnArray.push(obj)
    }

    this.setState({
      dropDownActualValues: tempArray,
      sheetTableColumns: newColumnArray,
      sheetTableData: respData
    })
  }

  addAnalysisToConfigureFile = (analysisName, systemName, organization, domain, programmingLanguage, release, description, uploadedDoc) => {
    console.log("requirement_file", uploadedDoc);
    debugger
    var formData = new FormData();
    formData.append("analysis_name", analysisName);
    formData.append("requirement_file", uploadedDoc);
    
    this.setState({
      isLoading: true
    }, async () => {
      let response = '';
      response = await _analysisService.analysisUpload(formData);

      this.setState({
        isLoading: false,
      }, () => {
        if (response.data.isSuccess) {
          let tempShowSheetsDropDown = false;
          let tempSheetsArray = [];
          let tempArray = [];
          let newColumnArray = [];

          // actual response
          let respSheetsArray = [];
          let respColumn = [];
          let respData = [];
          let tempWarningsInfoForSheet = {};

          if (response.data.data.hasOwnProperty('csv_data')) {
            tempShowSheetsDropDown = false;
            respColumn = response.data.data.csv_data.columns;
            respData = response.data.data.csv_data.columns_data;
            tempWarningsInfoForSheet = response.data.data.csv_data.warnings;
          } else {
            tempShowSheetsDropDown = true;
            respSheetsArray = response.data.data.sheets;
            respColumn = response.data.data.sheets_data[0].columns;
            respData = response.data.data.sheets_data[0].columns_data;
            tempWarningsInfoForSheet = response.data.data.sheets_data[0].warnings;
          }

          for (let idx = 0; idx < respSheetsArray.length; idx++) {
            let obj = {
              Id: respSheetsArray[idx].id,
              Name: respSheetsArray[idx].value
            };
            tempSheetsArray.push(obj);
          }


          for (let xx = 0; xx < respColumn.length; xx++) {
            if (xx <= 6) {
              tempArray.push(xx + 1)
            } else {
              tempArray.push(0);
            }
          }

          this.setState({
            dropDownActualValues: tempArray
          }, () => {
            for (let index = 0; index < respColumn.length; index++) {

              let obj = {
                title: <div className="uploadFileTableColumn">
                  <DropdownList
                    style={{ width: '33%' }}
                    value={this.state.dropDownActualValues[index]}
                    name={`${index + 1}`}
                    onChange={(event) => { this.onDropDwonTextChange(event, index) }}
                    placeholder="Select"
                    className="w-100"
                    wrapClass="wrap"
                    isRequired={false}
                    isDisabled={false}
                    options={this.state.dropDownUniqueListArray}
                  // errorState={true}
                  // errorMessage={this.state.programmingLanguageError}
                  />
                  {respColumn[index].value}
                </div>,
                dataIndex: respColumn[index].column,
                // width: '30%',
              }

              newColumnArray.push(obj)
            }

            console.log('Array: ', newColumnArray);

            this.setState({
              showSheetsDropDown: tempShowSheetsDropDown,
              wholeFileResponse: response.data,
              sheetsArray: tempSheetsArray,
              actualSheetTableColumns: respColumn,
              sheetTableColumns: newColumnArray,
              sheetTableData: respData,
              dropDownForSheet: 0,
              warningsInfoForSheet: tempWarningsInfoForSheet,
              nextFlag:response.data.data.sheets_data[0].Nextflag
            }, () => {
              this.setState({
                showConfigurePopup: true
              })
            })

          })

        }
        else {
          _notification.showMessage('error', response.data.errorMessage);
        }
      })
    })
  }

  addAnalysis = (analysisName, budget, systemName, organization, domain, programmingLanguage, release, description, uploadedDoc) => {
    debugger
    // console.log(`inside add analysis , ${ this.state.radioButtonValue}`);
    let tempTableData = [...this.state.tableData];
    let mainArray = [];
    let flagForGroupLevelOne = false;
    let flagForGroupLevelTwo = false;
    let flagForGroupLevelThree = false;
    let flagForGroupLevelFour = false;
    let flagForGroupLevelFive = false;
    for (let index = 0; index < tempTableData.length; index++) {
      const element = tempTableData[index];
      if (element.grouplevelone && element.grouplevelone != '') {
        flagForGroupLevelOne = true;
      }
      if (element.groupleveltwo && element.groupleveltwo != '') {
        flagForGroupLevelTwo = true;
      }
      if (element.grouplevelthree && element.grouplevelthree != '') {
        flagForGroupLevelThree = true;
      }
      if (element.grouplevelfour && element.grouplevelfour != '') {
        flagForGroupLevelFour = true;
      }
      if (element.grouplevelfive && element.grouplevelfive != '') {
        flagForGroupLevelFive = true;
      }
      let obj = {
        requirement_no: element.id,
        requirement_text: element.description,
        group_level1_text: element.grouplevelone,
        group_level2_text: element.groupleveltwo,
        group_level3_text: element.grouplevelthree,
        group_level4_text: element.grouplevelfour,
        group_level5_text: element.grouplevelfive
      }
      mainArray.push(obj);
    }

    var formData = new FormData();
    let finalObj = {};
    if (this.state.radioButtonValue === 0) {
      formData.append("analysis_name", analysisName);
      formData.append("budget", budget)
      formData.append("system_name", systemName);
      formData.append("organization", organization);
      formData.append("domain", domain);
      formData.append("language", parseInt(programmingLanguage));
      formData.append("release", release);
      formData.append("description", description);
      formData.append("isManual", 1);
      formData.append("group_level1_text", this.state.headerGroupLevelOneValue);
      formData.append("group_level2_text", this.state.headerGroupLevelTwoValue);
      //new params
      formData.append("group_level3_text", this.state.headerGroupLevelThreeValue);
      formData.append("group_level4_text", this.state.headerGroupLevelFourValue);
      formData.append("group_level5_text", this.state.headerGroupLevelFiveValue);

      formData.append("requirement_file", uploadedDoc);
      formData.append("status", this.state.valueForNewDevelopmentOrEnhancement);
      // formData.append("analyst", 1);//TODO: remove
    } else if (this.state.radioButtonValue === 1) {
      finalObj.analysis_name = analysisName;
      finalObj.budget = budget;
      finalObj.system_name = systemName;
      finalObj.organization = organization;
      finalObj.domain = domain;
      finalObj.language = parseInt(programmingLanguage);
      finalObj.release = release;
      finalObj.description = description;
      finalObj.group_level1_text = flagForGroupLevelOne ? this.state.headerGroupLevelOneValue : '';
      finalObj.group_level2_text = flagForGroupLevelTwo ? this.state.headerGroupLevelTwoValue : '';

      //new params
      finalObj.group_level3_text = flagForGroupLevelThree ? this.state.headerGroupLevelThreeValue : '';
      finalObj.group_level4_text = flagForGroupLevelFour ? this.state.headerGroupLevelFourValue : '';
      finalObj.group_level5_text = flagForGroupLevelFive ? this.state.headerGroupLevelFiveValue : '';

      finalObj.isManual = 2;
      finalObj.requirements = mainArray;
      finalObj.status = this.state.valueForNewDevelopmentOrEnhancement;
    }

    this.setState({
      isLoading: true
    }, async () => {
      let response = '';
      if (this.state.radioButtonValue === 0) {
        response = await _analysisService.addAnalysis(formData);
      } else {
        response = await _analysisService.addAnalysis(finalObj);
      }

      this.setState({
        isLoading: false
      }, () => {
        if (response.data.isSuccess) {
          this.setState({ analysisId: response.data.analysis_id });

          if (response.data.is_show_group_level1 === true || response.data.is_show_group_level2 === true || response.data.is_show_group_level3 === true||response.data.is_show_group_level4 === true||response.data.is_show_group_level5 === true) {
            this.setState({
              showGroupLevel1: response.data.is_show_group_level1,
              showGroupLevel2: response.data.is_show_group_level2,
              showGroupLevel3: response.data.is_show_group_level3,
              showGroupLevel4: response.data.is_show_group_level4,
              showGroupLevel5: response.data.is_show_group_level5,
              groupLevel1: response.data.group_level1_displayText,
              groupLevel2: response.data.group_level2_displayText,
              groupLevel3: response.data.group_level3_displayText,
              groupLevel4: response.data.group_level4_displayText,
              groupLevel5: response.data.group_level5_displayText,
              groupModalVisible: true,
              totalRequirementCount: response.data.requirements_count
            });
          }
          else {
            localStorage[SHOW_GROUP_LEVEL_1] = false;
            localStorage[SHOW_GROUP_LEVEL_2] = false;
            localStorage[SHOW_GROUP_LEVEL_3] = false;
            localStorage[SHOW_GROUP_LEVEL_4] = false;
            localStorage[SHOW_GROUP_LEVEL_5] = false;
            localStorage[GROUP_LEVEL_1_DISPLAY_TEXT] = '';
            localStorage[GROUP_LEVEL_2_DISPLAY_TEXT] = '';
            localStorage[GROUP_LEVEL_3_DISPLAY_TEXT] = '';
            localStorage[GROUP_LEVEL_4_DISPLAY_TEXT] = '';
            localStorage[GROUP_LEVEL_5_DISPLAY_TEXT] = '';
            this.props.history.push(routes.ViewRequirements.basePath + "/" + response.data.analysis_id);
          }
        }
        else {
          _notification.showMessage('error', response.data.errorMessage);
        }
      })
    })
  }

  onFileError = (errorMsg) => {
    this.setState({ fileError: errorMsg })
  }

  onRadioChange = e => {
    let systemName = this.state.systemName;
    if(e.target.value === 2){
      systemName = "";
    //   this.validateAnalysisDetails();
    }
      this.setState({
        radioButtonValue: e.target.value,
        systemName
      },async()=>{
        if(this.state.radioButtonValue === 2){
        let jiraUrl, jiraAuthToken, jiraUsername, jiraUrlError, jiraUsernameError, jiraAuthTokenError = "";
        let res = await _analysisService.getJiraAuthInfo();
          if (res.data.isSuccess) {
            jiraUrl = res.data.url ? res.data.url : "";
            jiraAuthToken = res.data.token ? res.data.token : "";
            jiraUsername = res.data.email ? res.data.email : "";
            jiraUrlError = res.data.url ? "success" : "";
            jiraUsernameError = res.data.email ? "success" : "";
            jiraAuthTokenError = res.data.token ? "success" : "";
          }
        this.setState({ jiraUrl, jiraAuthToken, jiraUsername, jiraUrlError, jiraUsernameError, jiraAuthTokenError });
      }
      });
    // }
  };

  onRadioChangeForNewDevelopmentOrEnhancement = e => {
    this.setState({
      valueForNewDevelopmentOrEnhancement: e.target.value,
    });
  };

  onTableChange = (pageSize, currentPage, sortField, sortOrder, filters) => {
    // TODO
  }

  setShowHeaderGroupLevelOnePopup = (showHeaderGroupLevelOnePopup) => {
    this.setState({
      showHeaderGroupLevelOnePopup: showHeaderGroupLevelOnePopup,
      headerGroupLevelOneValue: this.state.previousHeaderGroupLevelOneValue,
      headerGroupLevelOneValueState: ''
    })
  }

  setShowHeaderGroupLevelTwoPopup = (showHeaderGroupLevelTwoPopup) => {
    this.setState({
      showHeaderGroupLevelTwoPopup: showHeaderGroupLevelTwoPopup,
      headerGroupLevelTwoValue: this.state.previousHGroupLevelTwoValue,
      headerGroupLevelTwoValueState: ''
    })
  }

  setShowHeaderGroupLevelThreePopup = (showHeaderGroupLevelThreePopup) => {
    this.setState({
      showHeaderGroupLevelThreePopup: showHeaderGroupLevelThreePopup,
      headerGroupLevelThreeValue: this.state.previousHGroupLevelThreeValue,
      headerGroupLevelThreeValueState: ''
    })
  }

  setShowHeaderGroupLevelFourPopup = (showHeaderGroupLevelFourPopup) => {
    this.setState({
      showHeaderGroupLevelFourPopup: showHeaderGroupLevelFourPopup,
      headerGroupLevelFourValue: this.state.previousHGroupLevelFourValue,
      headerGroupLevelFourValueState: ''
    })
  }

  setShowHeaderGroupLevelFivePopup = (showHeaderGroupLevelFivePopup) => {
    this.setState({
      showHeaderGroupLevelFivePopup: showHeaderGroupLevelFivePopup,
      headerGroupLevelFiveValue: this.state.previousHGroupLevelFiveValue,
      headerGroupLevelFiveValueState: ''
    })
  }

  editGroupLevelName = (flag) => {
    this.setState({
      [flag]: true
    })
  }

  updateGroupLevel = (value) => {
    let tempColumns = [...this.state.columns];
debugger
    if (value === 'one') {
      if (this.state.headerGroupLevelOneValueState !== 'error') {
        let newObj = {
          title: <div style={{}} className="d-flex align-items-center">{this.state.headerGroupLevelOneValue}
            <Button type='link' className="p-0 ml-2" ghost onClick={() => this.editGroupLevelName("showHeaderGroupLevelOnePopup")}>
              <IconMarin iconName="edit" fill="rgb(95, 99, 104)" class="icon" />
            </Button>
          </div>,
          dataIndex: 'grouplevelone',
          width: '15%',
          render: tempColumns[2].render
        }
        tempColumns[2] = newObj;

        this.setState({
          showHeaderGroupLevelOnePopup: false,
          previousHeaderGroupLevelOneValue: this.state.headerGroupLevelOneValue,
          columns: tempColumns
        })
      }
    } else if (value === 'two') {
      if (this.state.headerGroupLevelTwoValueState !== 'error') {
        let newObj = {
          title: <div style={{}} className="d-flex align-items-center">{this.state.headerGroupLevelTwoValue}
            <Button type='link' className="p-0 ml-2" ghost onClick={() => this.editGroupLevelName("showHeaderGroupLevelTwoPopup")}>
              <IconMarin iconName="edit" fill="rgb(95, 99, 104)" class="icon" />
            </Button>
          </div>,
          dataIndex: 'groupleveltwo',
          width: '15%',
          render: tempColumns[3].render
        }
        tempColumns[3] = newObj;

        this.setState({
          showHeaderGroupLevelTwoPopup: false,
          previousHGroupLevelTwoValue: this.state.headerGroupLevelTwoValue,
          columns: tempColumns
        })
      }
    }else if (value === 'three') {
      if (this.state.headerGroupLevelThreeValueState !== 'error') {
        let newObj = {
          title: <div style={{}} className="d-flex align-items-center">{this.state.headerGroupLevelThreeValue}
            <Button type='link' className="p-0 ml-2" ghost onClick={() => this.editGroupLevelName("showHeaderGroupLevelThreePopup")}>
              <IconMarin iconName="edit" fill="rgb(95, 99, 104)" class="icon" />
            </Button>
          </div>,
          dataIndex: 'grouplevelthree',
          width: '15%',
          render: tempColumns[4].render
        }
        tempColumns[4] = newObj;

        this.setState({
          showHeaderGroupLevelThreePopup: false,
          previousHGroupLevelThreeValue: this.state.headerGroupLevelThreeValue,
          columns: tempColumns
        })
      }
    }else if (value === 'four') {
      if (this.state.headerGroupLevelFourValueState !== 'error') {
        let newObj = {
          title: <div style={{}} className="d-flex align-items-center">{this.state.headerGroupLevelFourValue}
            <Button type='link' className="p-0 ml-2" ghost onClick={() => this.editGroupLevelName("showHeaderGroupLevelFourPopup")}>
              <IconMarin iconName="edit" fill="rgb(95, 99, 104)" class="icon" />
            </Button>
          </div>,
          dataIndex: 'grouplevelfour',
          width: '15%',
          render: tempColumns[5].render
        }
        tempColumns[5] = newObj;

        this.setState({
          showHeaderGroupLevelFourPopup: false,
          previousHGroupLevelFourValue: this.state.headerGroupLevelFourValue,
          columns: tempColumns
        })
      }
    }else if (value === 'five') {
      if (this.state.headerGroupLevelFiveValueState !== 'error') {
        let newObj = {
          title: <div style={{}} className="d-flex align-items-center">{this.state.headerGroupLevelFiveValue}
            <Button type='link' className="p-0 ml-2" ghost onClick={() => this.editGroupLevelName("showHeaderGroupLevelFivePopup")}>
              <IconMarin iconName="edit" fill="rgb(95, 99, 104)" class="icon" />
            </Button>
          </div>,
          dataIndex: 'grouplevelfive',
          width: '15%',
          render: tempColumns[6].render
        }
        tempColumns[6] = newObj;

        this.setState({
          showHeaderGroupLevelFivePopup: false,
          previousHGroupLevelFiveValue: this.state.headerGroupLevelFiveValue,
          columns: tempColumns
        })
      }
    }
  }
  // onSearch = (searchText) => {
  //   // setOptions(
  //   //   !searchText ? [] : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)],
  //   // );
  //   this.setState({
  //     options: !searchText ? [] : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)]
  //   })
  // };

  // onSelect = (data) => {
  //   console.log('onSelect', data);
  // };

  // onChange = (data) => {
  //   console.log('Options: ', this.state.options);
  //   console.log('AutoComplete data: ', data);
  //   
  //   this.setState({
  //     tempAutoCompleteValue: data
  //   })
  // };

  onPopupTextChange = (event, name) => {
    
    let value = event.target.value;
    switch (name) {
      case "jiraUrl":
        if (_validator.verifyUrl(value)) {
          this.setState({
            [name]: value,
            [name + "Error"]: "success",
            [name + "ErrorMsg"]: ""
          });
        } else {
          this.setState({
            [name]: value,
            [name + "Error"]: "error",
            [name + "ErrorMsg"]: "Please enter valid jira url"
          });
        }
        break;

      case "jiraUsername":
        if (_validator.verifyEmail(value)) {
          this.setState({
            [name]: value,
            [name + "Error"]: "success",
            [name + "ErrorMsg"]: ""
          });
        } else {
          this.setState({
            [name]: value,
            [name + "Error"]: "error",
            [name + "ErrorMsg"]: "Please enter valid jira email"
          });
        }
        break;

      case "jiraAuthToken":
        if (_validator.verifyBlank(value)) {
          this.setState({
            [name]: value,
            [name + "Error"]: "success",
            [name + "ErrorMsg"]: ""
          });
        } else {
          this.setState({
            [name]: value,
            [name + "Error"]: "error",
            [name + "ErrorMsg"]: "Please enter jira Auth Token"
          });
        }
        break;

      default:
        this.setState({ [name]: value, [name + "Error"]: "success" });
    }
  }

  onJiraLogin = async()=>{
    const {jiraUrl, jiraUsername, jiraAuthToken, jiraUrlError, jiraUsernameError, jiraAuthTokenError} = this.state;
    if(jiraUrlError==="success" && jiraUsernameError==="success" && jiraAuthTokenError==="success"){
      this.setState({ isLoading: true });
      let obj ={};
      obj.url = jiraUrl;
      obj.email = jiraUsername;
      obj.token = jiraAuthToken;
      try {
        let resp = await _analysisService.jiraAuthentication(obj);
        if (resp.data.isSuccess) {
          let projectData = [];
          let projects = [];
          let res = await _analysisService.getJiraMetadata();
          if (res.data.isSuccess) {
            projectData = res.data.data;
            if(projectData && projectData.length > 0){
              for (let index = 0; index < projectData.length; index++) {
                projects.push({label:projectData[index].projectName, value:projectData[index].projectKey})
              }
            }

            this.setState({isLoading: false, isJiraLoggedIn:true, projectList: projects, projectData});
          }else{
            this.setState({
              isLoading: false
            }, () => {
                _notification.showMessage('error', res.data.errorMessage);
            })
          }
        
        } else {
            this.setState({
                isLoading: false
            }, () => {
                _notification.showMessage('error', resp.data.errorMessage);
            })
        }
      }catch(e){
        this.setState({ isLoading: false })
        // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
      }
    }else{
      if(jiraUrlError!=="success"){
        this.setState({
          jiraUrlError: "error",
          jiraUrlErrorMsg: "Please enter jira url"
        });
      }

      if(jiraUsernameError!=="success"){
        this.setState({
          jiraUsernameError: "error",
          jiraUsernameErrorMsg: "Please enter jira email"
        });
      }

      if(jiraAuthTokenError!=="success"){
        this.setState({
          jiraAuthTokenError: "error",
          jiraAuthTokenErrorMsg: "Please enter jira Auth Token"
        });
      }
      
    }
  }

  cancelJiraAuthPopup=()=>{
    this.setState({showJiraPopup:false, isJiraLoggedIn: false, isMetaData:false, project: undefined, issueStatus: [], issueType: []});
  }

  cancelJiraMetaDataPopup=()=>{
    this.setState({isJiraLoggedIn:false, isMetaData:false});
  }

  onJiraConfigure=async()=>{
    let {project, issueType, issueStatus, issueList} = this.state;
    if(issueType.indexOf('All') > -1){
      // issueType = this.state.issueTypeList.filter(x => x !== 'All');
      issueType = [...this.state.issueTypeList];
    }
    if(issueStatus.indexOf('All') > -1){
      // issueStatus = this.state.issueStatuses.filter(x => x !== 'All');
      issueStatus = [...this.state.issueStatuses];
    }

    if(project !== "" && issueType && issueType.length > 0 && issueStatus && issueStatus.length > 0){
      this.setState({ isLoading: true });
      let res = await _analysisService.getJiraIssueList(project, issueType, issueStatus);
      if (res.data.isSuccess) {
            let issueList = res.data.data;
            this.setState({isLoading: false, isMetaData:true, issueList},()=>{
              this.getIssueList();
            });
      }else{
        this.setState({
            isLoading: false
        }, () => {
            _notification.showMessage('error', res.data.errorMessage);
        })
      }
    }else{
      if(this.state.projectError !== "success"){
        this.setState({
          projectError:"error"
        });
      }
      if(this.state.issueStatusError !== "success"){
        this.setState({
          issueStatusError:"error"
        });
      }
      if(this.state.issueTypeError !== "success"){
        this.setState({
          issueTypeError:"error"
        });
      }
    }
    
  }

  onColumnDropDwonChange = (event, index) => {

    let tempArray = [...this.state.dropDownActualValues];
    for (let idx = 0; idx < tempArray.length; idx++) {
      const element = tempArray[idx];
      if (element === parseInt(event.target.value)) {
        tempArray[idx] = 0;
      }
    }
    tempArray[index] = event.target.value === "" ? 0 : parseInt(event.target.value);

    let newColumnArray = [];

    let respColumn = [...this.state.actualJiraColumn];
//2222222222222222222
    for (let index = 0; index < respColumn.length; index++) {
      let obj = {
        title: <div className="uploadFileTableColumn">
          <DropdownList
            style={{ width: '33%' }}
            value={tempArray[index]}
            name={`${index + 1}`}
            onChange={(event) => { this.onColumnDropDwonChange(event, index) }}
            placeholder="Select"
            className="w-100"
            wrapClass="wrap"
            isRequired={false}
            isDisabled={false}
            options={this.state.dropDownUniqueListArray}
          />
          {respColumn[index]}
        </div>,
        dataIndex: respColumn[index],
        // width: '30%',
      }
      newColumnArray.push(obj)
    }

    this.setState({
      dropDownActualValues: tempArray,
      issueTableColumns: newColumnArray
    })
  }

  getIssueList=()=>{
    const {issueList} = this.state;
    let newColumnArray=[];
    let respColumn =[], tempArray = [];
    if(issueList && issueList.length > 0){
      let issueObj = issueList[0];

      Object.keys(issueObj).map(key => {

        console.log('key name = ', key);
        if(key !== "__uniqueId"){
          respColumn.push(key);
        }
     })
    }

    //Loop to set initial mapping values
    for (let xx = 0; xx < respColumn.length; xx++) {
      if (xx <= 6) {
        tempArray.push(xx + 1)
      } else {
        tempArray.push(0);
      }
    }

    this.setState({
      dropDownActualValues: tempArray,
      actualJiraColumn:respColumn
    }, () => {

      for (let index = 0; index < respColumn.length; index++) {

        let obj = {
          title: <div className="uploadFileTableColumn">
            <DropdownList
              style={{ width: '33%' }}
              value={this.state.dropDownActualValues[index]}
              name={`${index + 1}`}
              onChange={(event) => { this.onColumnDropDwonChange(event, index) }}
              placeholder="Select"
              className="w-100"
              wrapClass="wrap"
              isRequired={false}
              isDisabled={false}
              options={this.state.dropDownUniqueListArray}
            // errorState={true}
            // errorMessage={this.state.programmingLanguageError}
            />
            {respColumn[index]}
          </div>,
          dataIndex: respColumn[index],
          ellipsis:true,
          width: '150px',
        }

        newColumnArray.push(obj)
      }

      this.setState({
        issueTableColumns: newColumnArray
      })
    })

  }

  onJiraIssueSaveClick=()=>{
    let { analysisName, budget, systemName, organization, domain, programmingLanguage, release, description, project, issueType, issueStatus } = this.state;
  
    let tempArray = [...this.state.dropDownActualValues];
    let actualKeys = [...this.state.actualJiraColumn];
    let uniqueIdColumn = "", reqTextColumn = "", grpLvl1Column = "", grpLvl2Column ="",
    grpLvl3Column ="", grpLvl4Column ="", grpLvl5Column ="";
    let dropDwonErrorForUniqueId = false;
    let dropDwonErrorForRequirementText = false;
    for (let z = 0; z < tempArray.length; z++) {
      if(tempArray[z] == 1){
        uniqueIdColumn = actualKeys[z];
        dropDwonErrorForUniqueId = true;
      }
      if(tempArray[z] == 2){
        reqTextColumn = actualKeys[z];
        dropDwonErrorForRequirementText = true;
      }
      if(tempArray[z] == 3){
        grpLvl1Column = actualKeys[z];
      }
      if(tempArray[z] == 4){
        grpLvl2Column = actualKeys[z];
      }
      if(tempArray[z] == 5){
        grpLvl3Column = actualKeys[z];
      }
      if(tempArray[z] == 6){
        grpLvl4Column = actualKeys[z];
      }
      if(tempArray[z] == 7){
        grpLvl5Column = actualKeys[z];
      }
    }
              
    let reqObj = {};
    reqObj.analysis_name = analysisName;
    reqObj.budget = budget;
    reqObj.system_name = systemName;
    reqObj.organization = organization;
    reqObj.domain = domain;
    reqObj.language = parseInt(programmingLanguage);
    reqObj.release = release;
    reqObj.description = description;
    reqObj.status = this.state.valueForNewDevelopmentOrEnhancement;

    // column mapping
    reqObj.unique_id_column = uniqueIdColumn;
    reqObj.requirement_text_column = reqTextColumn;
    reqObj.group_level_one_column = grpLvl1Column;
    reqObj.group_level_two_column = grpLvl2Column;
    //new params
    reqObj.group_level_three_column = grpLvl3Column;
    reqObj.group_level_four_column = grpLvl4Column;
    reqObj.group_level_five_column = grpLvl5Column;

    if(issueType.indexOf('All') > -1){
      // issueType = this.state.issueTypeList.filter(x => x !== 'All');
      issueType = [...this.state.issueTypeList];
    }
    if(issueStatus.indexOf('All') > -1){
      // issueStatus = this.state.issueStatuses.filter(x => x !== 'All');
      issueStatus = [...this.state.issueStatuses];
    }

    reqObj.project =  project;
    reqObj.issueType = issueType;
    reqObj.issueStatus = issueStatus;

    reqObj.isManual = 3;
    if(dropDwonErrorForUniqueId && dropDwonErrorForRequirementText){
      this.setState({
        isLoading: true
      }, async () => {
        let response = '';
        
        response = await _analysisService.addAnalysis(reqObj);
      
        this.setState({
          isLoading: false
        }, () => {
          if (response.data.isSuccess) {
            this.setState({ analysisId: response.data.analysis_id });
              localStorage[SHOW_GROUP_LEVEL_1] = (grpLvl1Column && grpLvl1Column!="") ? true:false;
              localStorage[SHOW_GROUP_LEVEL_2] = (grpLvl2Column && grpLvl2Column!="") ? true:false;
              localStorage[SHOW_GROUP_LEVEL_3] = (grpLvl3Column && grpLvl3Column!="") ? true:false;
              localStorage[SHOW_GROUP_LEVEL_4] = (grpLvl4Column && grpLvl4Column!="") ? true:false;
              localStorage[SHOW_GROUP_LEVEL_5] = (grpLvl5Column && grpLvl5Column!="") ? true:false;
              localStorage[GROUP_LEVEL_1_DISPLAY_TEXT] = (grpLvl1Column && grpLvl1Column!="") ? grpLvl1Column:'';
              localStorage[GROUP_LEVEL_2_DISPLAY_TEXT] = (grpLvl2Column && grpLvl2Column!="") ? grpLvl2Column:'';
              localStorage[GROUP_LEVEL_3_DISPLAY_TEXT] = (grpLvl3Column && grpLvl3Column!="") ? grpLvl3Column:'';
              localStorage[GROUP_LEVEL_4_DISPLAY_TEXT] = (grpLvl4Column && grpLvl4Column!="") ? grpLvl4Column:'';
              localStorage[GROUP_LEVEL_5_DISPLAY_TEXT] = (grpLvl5Column && grpLvl5Column!="") ? grpLvl5Column:'';
              this.props.history.push(routes.ViewRequirements.basePath + "/" + response.data.analysis_id);
          }
          else {
            _notification.showMessage('error', response.data.errorMessage);
          }
        })
      })
    }
  }

  handleProjectChange=(val)=>{
    let { projectData } =this.state;
    let selectedProj = projectData.filter(x => x.projectKey === val);
    let issueTypeList = [], issueStatuses = [];
    let systemName = "";
    if(selectedProj && selectedProj.length>0){
      issueTypeList = selectedProj[0].issueType;
      issueStatuses = selectedProj[0].status;
      systemName = selectedProj[0].projectName;
    }
    this.setState({project:val, systemName, projectError:val?"success":"", issueTypeList,issueStatuses, issueStatus:[], issueType:[]});
  }

  handleFilterChange=(state, val)=>{
    
    if(val && val.length > 0){
      if(val.indexOf('All') >= 0){
        this.setState({[state]:['All'], [state + "Error"]:"success"});
      }else{
        this.setState({[state]:val, [state + "Error"]:"success"});
      }
    }else{
      this.setState({[state]:val, [state + "Error"]:"error"});
    }
    
  }

  render() {
    let descriptionLable = <div>
      System Description
      <Tooltip placement="top" title='Write your scope and objective of the analysis' >
        <span className=" ml-2"><IconMarin iconName="info-icon" height="18" width="18" fill="rgb(95, 99, 104)" class="icon" /></span>
      </Tooltip>
    </div>

    let languageLable = <span>
      Programming Language <span className="color-red">*</span>
      <Tooltip placement="top" title='Please select the programming language for the application’s source code.  If there will be more than one language, select the most prevalent language' >
        <span className=" ml-2"><IconMarin iconName="info-icon" height="18" width="18" fill="rgb(95, 99, 104)" class="icon" /></span>
      </Tooltip>
    </span>

    let typeLable = <span>
      Type
    </span>
    let trs = '';
    if (fileDataHeader.length > 0) {
      for (let index = 0; index < fileDataHeader.length; index++) {
        const element = fileDataHeader[index];

      }
    }

    return (
      <div className="container  analysisWrap pt-3 pb-3 p-sm-4 p-md-5">

        {/* {
          fileData.map((fileItem, index) => {
            if (index == 0) {
              return 
            }
          })
        }
        <br />
        <br />
        <br />
        <br />
        <br /> */}

        <h5 className="heading mb-4">New Project Analysis</h5>
        <div className="row flex-sm-row ">
          <div className="col-sm-4">
            <MyInput
              type="text"
              value={this.state.analysisName}
              name="analysisName"
              onChange={this.onTextChange}
              heading="Analysis Name"
              className="w-100"
              wrapClass="wrap"
              isRequired={true}
              errorState={this.state.analysisNameState}
              errorMessage={this.state.analysisNameError}
            />

          </div>
          <div className="col-sm-4">
            <MyInput
              type="text"
              value={this.state.systemName}       
              name="systemName"
              onChange={this.onTextChange}
              heading="System Name"
              className="w-100"
              wrapClass="wrap"
              disabled={this.state.radioButtonValue === 2 ? true : false}
              isRequired={this.state.radioButtonValue !== 2 ? true : false}
              errorState={this.state.systemNameState}
              errorMessage={this.state.systemNameError}
            />
          </div>
          <div className="col-sm-4">
            <MyInput
              type="text"
              value={this.state.organization}
              name="organization"
              onChange={this.onTextChange}
              heading="Organization"
              className="w-100"
              wrapClass="wrap"
              isRequired={false}
              errorState={this.state.organizationState}
              errorMessage={this.state.organizationError}
            />
          </div>
        </div>
        <div className="row flex-sm-row">
          <div className="col-sm-4">
            <DropdownList
              value={this.state.programmingLanguage}
              name="programmingLanguage"
              onChange={this.onTextChange}
              placeholder="Select Language"
              heading={languageLable}
              className="w-100"
              wrapClass="wrap"
              isRequired={false}
              options={this.state.programmingLanguages}
              errorState={this.state.programmingLanguageState}
              errorMessage={this.state.programmingLanguageError}
            />
          </div>
          <div className="col-sm-4">
            <MyInput
              type="text"
              value={this.state.release}
              name="release"
              onChange={this.onTextChange}
              heading="Release"
              className="w-100"
              wrapClass="wrap"
              isRequired={false}
              errorState={this.state.releaseState}
              errorMessage={this.state.releaseError}
            />
          </div>
          <div className="col-sm-4">
            <MyInput
              type="text"
              value={this.state.domain}
              name="domain"
              onChange={this.onTextChange}
              heading="Domain"
              className="w-100"
              wrapClass="wrap"
              isRequired={false}
              errorState={this.state.domainState}
              errorMessage={this.state.domainError}
            />
          </div>
        </div>
        <div className="row flex-sm-row">
          <div className="col-sm-4">
            <DropdownList
              value={this.state.valueForNewDevelopmentOrEnhancement}
              name="valueForNewDevelopmentOrEnhancement"
              onChange={this.onTextChange}
              placeholder="Select Type"
              heading={typeLable}
              className="w-100"
              wrapClass="wrap"
              isRequired={true}
              options={newDevelopmentOrEnhancementDropDwonArray}
              errorState={this.state.valueForNewDevelopmentOrEnhancementState}
              errorMessage={this.state.valueForNewDevelopmentOrEnhancementError}
            />
          </div>
        
        {/* Budget Input field */}
          <div className="col-sm-4">
              <div className="d-flex">
                      <label for="">
                        Budget
                      </label>
                    </div>
                     {/* <MyInput
                type="text"
                value={this.state.budget */}
                {/* name="budget"
                onChange = {this.onTextChange}
                heading="Budget"
                className="w-100"
                wrapClass="wrap"
                isRequired={false}  
                // errorState={this.state.budgetState}
                // errorMessage={this.state.budgetError}
              />  */}
                <NumberFormat
                        decimalScale={0}
                        onChange = {this.onTextChange}
                        type = "text"
                        value={ this.state.budget.replace(/[^0-9]*/g,'')}
                        name="budget"
                        className="w-100"
                        wrapClass="wrap"
                        thousandSeparator={true} 
                        prefix={'$'}
                      />
          </div>
        </div>
          {/* Budget End */}

        <div className="row">
          <div className="col-12">
            <TextArea
              rows={3}
              value={this.state.description}
              name="description"
              onChange={this.onTextChange}
              heading={descriptionLable}
              className="w-100"
              wrapClass="wrap"
              isRequired={false}
              errorState={this.state.descriptionState}
              errorMessage={this.state.descriptionError}
            />
            {/* <svg style={{ height: '16px', width: '16px' }} onClick={() => this.setModalDiscription(true)} className="icon">
                <use xlinkHref={svgIcons + '#info-icon'}></use>
              </svg> */}
          </div>
        </div>
        {/* <div className="projectStatus">
          <Radio.Group
            style={{ display: 'flex' }}
            onChange={this.onRadioChangeForNewDevelopmentOrEnhancement}
            className="mb-sm-4 mt-sm-4" value={this.state.valueForNewDevelopmentOrEnhancement}>
            <Radio value={0} style={{ display: 'flex' }}>
              <div className="d-flex">
                <label className="card-holder-name mr-4">New Development</label>
              </div>
            </Radio>
            <Radio value={1} style={{ display: 'flex' }}>
              <label className="card-holder-name mr-4">Enhancement</label>
            </Radio>
          </Radio.Group>
        </div> */}
        <div className="projectStatus">
          <Radio.Group style={{ display: 'flex' }} onChange={this.onRadioChange} className="mb-sm-4 mt-sm-4" value={this.state.radioButtonValue}>
            <Radio value={0} style={{ display: 'flex' }}>
              <div className="d-flex">
                <label className="card-holder-name mr-4">Upload Document {this.state.radioButtonValue === 0 ? <span className="color-red">*</span> : null}</label>
              </div>
              {
                this.state.radioButtonValue === 0
                  ?
                  <div
                    onClick={() => this.setdownloadModalVisible(true)}
                    className="downloadTempleteBtn">
                    <img src={downloadIcon} height="12px" className="align-middle mr-2" />
                    <span>Download Template</span>
                  </div>
                  :
                  null
              }
            </Radio>
            <Radio value={1} style={{ display: 'flex' }}>
              <label className="card-holder-name mr-4">Manual Entry {this.state.radioButtonValue === 1 ? <span className="color-red">*</span> : null}</label>
            </Radio>
            <Radio value={2} style={{ display: 'flex' }}>
              <label className="card-holder-name mr-4">Import From JIRA {this.state.radioButtonValue === 2 ? <span className="color-red">*</span> : null}</label>
            </Radio>
          </Radio.Group>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              {/* <div className="d-flex">
                <label className="card-holder-name mr-4">Upload Document <span className="color-red">*</span></label>
                <div onClick={() => this.setdownloadModalVisible(true)} className="downloadTempleteBtn"><img src={downloadIcon} height="12px" className="align-middle mr-2" /><span>Download Template</span></div>
              </div> */}
              {this.state.radioButtonValue === 0 ? <FileUpload onFileDrop={this.onFileDrop} onError={this.onFileError} /> : null}
              {this.state.radioButtonValue === 0 ? <label className="color-red">{this.state.fileError}</label> : null}
              {
                this.state.radioButtonValue === 1 ?
                  <ReactTable
                    // ref={this.tblRef}
                    hidePagination={true}
                    columns={this.state.columns} // headers
                    data={this.state.tableData} // data
                    loading={this.state.loading}
                    onTableChange={this.onTableChange}
                    // totalRecords={this.state.totalRecords}
                    // onRowClick={this.onRowClick}
                    className="manualEntry_Table"
                  />
                  :
                  null
              }
              {
                this.state.radioButtonValue === 1
                  ?
                  <div style={{ paddingTop: 10 }}>
                    <ButtonComponent
                      id="btnCancel"
                      type="button"
                      value="Add Row"
                      className="btn-secondary equal-size-button mr-4" onClick={this.addNewRecord} />
                  </div>
                  :
                  null
              }
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className=" form-action d-flex justify-content-center">
              <ButtonComponent id="btnCancel" type="button" value="Cancel"
                className="btn-secondary equal-size-button mr-4" onClick={this.onCancel} />
              {/* <ButtonComponent id="btnCancel" type="button" value="Configure"
                className="btn-secondary equal-size-button mr-4" onClick={this.onConfigure} /> */}
              <ButtonComponent
                id="btnSubmit"
                type="button"
                value="Next"
                className="btn-primary equal-size-button"
                onClick={this.onSubmit} />
            </div>
          </div>
        </div>

        {/* edit header column value for group level one */}
        <Modal
          centered={true}
          width={400}
          visible={this.state.showHeaderGroupLevelOnePopup}
          bodyStyle={{ padding: 0 }}
          footer={false}
          onOk={() => this.setShowHeaderGroupLevelOnePopup(false)}
          onCancel={() => this.setShowHeaderGroupLevelOnePopup(false)}>
          <div className="container  analysisWrap pt-3 pb-3 p-sm-4 p-md-5" >
            <MyInput
              type="text"
              value={this.state.headerGroupLevelOneValue}
              name="headerGroupLevelOneValue"
              onChange={this.onTextChange}
              heading="Group level 1"
              className="w-100"
              maxLength={50}
              wrapClass="wrap"
              isRequired={false}
              errorState={this.state.headerGroupLevelOneValueState}
              errorMessage={this.state.headerGroupLevelOneValueError}
            />
            <div className="row">
              <div className="col-12">
                <div className=" form-action d-flex justify-content-center">
                  <ButtonComponent
                    // id="btnCancel"
                    type="button"
                    value="Submit"
                    className="btn-primary  equal-size-button mr-4"
                    onClick={() => { this.updateGroupLevel('one') }} />
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          title={"Import From JIRA"}
          centered={true}
          maskClosable={false}
          width={1200}
          visible={this.state.showJiraPopup}
          bodyStyle={{ padding: 0 }}
          footer={false}
          onOk={this.onJiraLogin}
          className="jiraModl"
          onCancel={this.cancelJiraAuthPopup}>
          <div className="analysisWrap pt-3 pb-3 p-sm-4 p-md-5">
            {!this.state.isJiraLoggedIn && <div className="w-50 mr-center">
                <MyInput
                  type="text"
                  value={this.state.jiraUrl}
                  name="jiraUrl"
                  onChange={(e)=>{this.onPopupTextChange(e,"jiraUrl")}}
                  heading="JIRA Url"
                  className="w-100"
                  wrapClass="wrap"
                  isRequired={true}
                  errorMessage={this.state.jiraUrlErrorMsg}
                  errorState={this.state.jiraUrlError}
                />

                <MyInput
                  type="text"
                  value={this.state.jiraUsername}
                  name="jiraUsername"
                  onChange={(e)=>{this.onPopupTextChange(e,"jiraUsername")}}
                  heading="Email"
                  className="w-100"
                  wrapClass="wrap"
                  isRequired={true}
                  errorMessage={this.state.jiraUsernameErrorMsg}
                  errorState={this.state.jiraUsernameError}
                />

                <MyInput
                  type="text"
                  value={this.state.jiraAuthToken}
                  name="jiraAuthToken"
                  onChange={(e)=>{this.onPopupTextChange(e,"jiraAuthToken")}}
                  heading="Auth Token"
                  className="w-100"
                  wrapClass="wrap"
                  isRequired={true}
                  errorMessage={this.state.jiraAuthTokenErrorMsg}
                  errorState={this.state.jiraAuthTokenError}
                  isInfoLabel={true}
                  infoText={
                    <span>To generate auth token, please check this 
                      <a href="https://support.atlassian.com/atlassian-account/docs/manage-api-tokens-for-your-atlassian-account/" target='_blank'> link</a>
                    </span>
                  }
                />
                
                <br/>
                <br/>
                <br/>
                <br/>
                <div className="row">
                  <div className="col-12">
                    <div className=" form-action d-flex justify-content-center">
                      <ButtonComponent
                        type="button"
                        value="Login"
                        className="btn-primary equal-size-button mr-4"
                        onClick={this.onJiraLogin } />
                    </div>
                  </div>
                </div>
            </div> }
            {this.state.isJiraLoggedIn && !this.state.isMetaData && 
            <div>
              <h4>Import Requirements</h4>
              <div>Use the filters below to narrow down what NEW issues will be imported from JIRA</div>
              <div>Issues that have already been brought in from JIRA will NOT be removed when filters are changed</div>
              
              <br/>
              
              <label>Project<span className="color-red">*</span></label>
              <Select
                value={this.state.project}
                className= { this.state.projectError === "error" ? "has-error searchFilter" : "searchFilter" }
                style={{ width: '100%', padding: '0px' }}
                placeholder="Select Project"
                onChange={this.handleProjectChange}
                allowClear={true}
                optionFilterProp="label"
              >
                {this.state.projectList.map(obj => (
                  <Option key={obj.value} value={obj.value} label={obj.label}>{obj.label}</Option>
                ))}
              </Select>
              {this.state.projectError ==="error" &&<label className="color-red d-block">Please select project</label>}
              {this.state.projectError !=="error" &&<br />}
              {this.state.projectError !=="error" &&<br />}
              <label>Issue type<span className="color-red">*</span></label>
              <Select
               className= { this.state.issueTypeError === "error" ? "has-error searchFilter" : "searchFilter" }
                value={this.state.issueType}
                mode="multiple"
                disabled={this.state.project === "" || this.state.project === undefined}
                style={{ width: '100%', padding: '0px' }}
                placeholder="Issue Type"
                onChange={(val) => this.handleFilterChange("issueType", val)}
                allowClear={true}
                optionFilterProp="label"
              >
                <Option key='All' value='All'>All</Option>
                {this.state.issueTypeList.map(obj => (
                   <Option key={obj} value={obj} disabled={this.state.issueType.indexOf('All') >= 0}>{obj}</Option>
                ))}
              </Select>
              {this.state.issueTypeError ==="error" &&<label className="color-red d-block">Please select at least one type.</label>}
              {this.state.issueTypeError !=="error" &&<br />}
              {this.state.issueTypeError !=="error" &&<br />}
              <label>Status<span className="color-red">*</span></label>
              <Select
                value={this.state.issueStatus}
                className= { this.state.issueStatusError === "error" ? "has-error searchFilter" : "searchFilter" }
                mode="multiple"
                disabled={this.state.project === "" || this.state.project === undefined}
                style={{ width: '100%', padding: '0px' }}
                placeholder="Select Filters"
                onChange={(val) => this.handleFilterChange("issueStatus", val)}
                allowClear={true}
                optionFilterProp="label"
              >
                <Option key='All' value='All'>All</Option>
                {this.state.issueStatuses.map(obj => (
                  <Option key={obj} value={obj} disabled={this.state.issueStatus.indexOf('All') >= 0}>{obj}</Option>
                ))}
              </Select>
              {this.state.issueStatusError ==="error" &&<label className="color-red d-block">Please select at least one status</label>}
              {this.state.issueStatusError !=="error" && <br />}
              {this.state.issueStatusError !=="error" && <br />}
              <div className="row">
                <div className="col-12">
                  <div className=" form-action d-flex justify-content-center">
                    <ButtonComponent id="btnBack" type="button" value="Back"
                      className="btn-secondary equal-size-button mr-4" onClick={this.cancelJiraMetaDataPopup} />
                    <ButtonComponent id="btnSubmit" type="button" value="Next"
                      className="btn-primary equal-size-button" onClick={this.onJiraConfigure} />
                  </div>
                </div>
              </div>
            </div>
           } 

            {this.state.isJiraLoggedIn && this.state.isMetaData &&
             <div>
                <ReactTable
                  hidePagination={true}
                  columns={this.state.issueTableColumns}
                  data={this.state.issueList}
                  loading={this.state.loading}
                  onTableChange={this.onTableChange}
                  className="uploadFileTable"
                  scroll={{x:(this.state.issueTableColumns.length*100)}}
                />

              <div className="intro">
              <br />

                {/* -Showing first 5 requirements from the uploaded file<br></br> */}
                -You can select which sheet and columns you want to use for analysis<br>
                </br>
                -You have to select atleast unique Id and requirement text columns. Group level 1 and Group level 2 columns are optional.
            </div>

                <br />
                <br />
                <div className="row">
                  <div className="col-12">
                    <div className=" form-action d-flex justify-content-center">
                      <ButtonComponent id="btnBack" type="button" value="Back"
                        className="btn-secondary equal-size-button mr-4" onClick={() => { this.setState({ isMetaData: false }) }} />
                      <ButtonComponent id="btnSubmit" type="button" value="Next"
                        className="btn-primary equal-size-button" onClick={this.onJiraIssueSaveClick} />
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </Modal>

        {/* edit header column value for group level two */}
        <Modal
          centered={true}
          width={400}
          visible={this.state.showHeaderGroupLevelTwoPopup}
          bodyStyle={{ padding: 0 }}
          footer={false}
          onOk={() => this.setShowHeaderGroupLevelTwoPopup(false)}
          onCancel={() => this.setShowHeaderGroupLevelTwoPopup(false)}>
          <div className="container  analysisWrap pt-3 pb-3 p-sm-4 p-md-5" >
            <MyInput
              type="text"
              value={this.state.headerGroupLevelTwoValue}
              name="headerGroupLevelTwoValue"
              onChange={this.onTextChange}
              heading="Group level 2"
              className="w-100"
              wrapClass="wrap"
              maxLength={50}
              isRequired={false}
              errorState={this.state.headerGroupLevelTwoValueState}
              errorMessage={this.state.headerGroupLevelTwoValueError}
            />
            <div className="row">
              <div className="col-12">
                <div className=" form-action d-flex justify-content-center">
                  <ButtonComponent
                    // id="btnCancel"
                    type="button"
                    value="Submit"
                    className="btn-primary  equal-size-button mr-4"
                    onClick={() => { this.updateGroupLevel('two') }} />
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* edit header column value for group level three */}
        <Modal
          centered={true}
          width={400}
          visible={this.state.showHeaderGroupLevelThreePopup}
          bodyStyle={{ padding: 0 }}
          footer={false}
          onOk={() => this.setShowHeaderGroupLevelThreePopup(false)}
          onCancel={() => this.setShowHeaderGroupLevelThreePopup(false)}>
          <div className="container  analysisWrap pt-3 pb-3 p-sm-4 p-md-5" >
            <MyInput
              type="text"
              value={this.state.headerGroupLevelThreeValue}
              name="headerGroupLevelThreeValue"
              onChange={this.onTextChange}
              heading="Group level 3"
              className="w-100"
              wrapClass="wrap"
              maxLength={50}
              isRequired={false}
              errorState={this.state.headerGroupLevelThreeValueState}
              errorMessage={this.state.headerGroupLevelThreeValueError}
            />
            <div className="row">
              <div className="col-12">
                <div className=" form-action d-flex justify-content-center">
                  <ButtonComponent
                    // id="btnCancel"
                    type="button"
                    value="Submit"
                    className="btn-primary  equal-size-button mr-4"
                    onClick={() => { this.updateGroupLevel('three') }} />
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* edit header column value for group level four */}
        <Modal
          centered={true}
          width={400}
          visible={this.state.showHeaderGroupLevelFourPopup}
          bodyStyle={{ padding: 0 }}
          footer={false}
          onOk={() => this.setShowHeaderGroupLevelFourPopup(false)}
          onCancel={() => this.setShowHeaderGroupLevelFourPopup(false)}>
          <div className="container  analysisWrap pt-3 pb-3 p-sm-4 p-md-5" >
            <MyInput
              type="text"
              value={this.state.headerGroupLevelFourValue}
              name="headerGroupLevelFourValue"
              onChange={this.onTextChange}
              heading="Group level 4"
              className="w-100"
              wrapClass="wrap"
              maxLength={50}
              isRequired={false}
              errorState={this.state.headerGroupLevelFourValueState}
              errorMessage={this.state.headerGroupLevelFourValueError}
            />
            <div className="row">
              <div className="col-12">
                <div className=" form-action d-flex justify-content-center">
                  <ButtonComponent
                    // id="btnCancel"
                    type="button"
                    value="Submit"
                    className="btn-primary  equal-size-button mr-4"
                    onClick={() => { this.updateGroupLevel('four') }} />
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* edit header column value for group level five */}
        <Modal
          centered={true}
          width={400}
          visible={this.state.showHeaderGroupLevelFivePopup}
          bodyStyle={{ padding: 0 }}
          footer={false}
          onOk={() => this.setShowHeaderGroupLevelFivePopup(false)}
          onCancel={() => this.setShowHeaderGroupLevelFivePopup(false)}>
          <div className="container  analysisWrap pt-3 pb-3 p-sm-4 p-md-5" >
            <MyInput
              type="text"
              value={this.state.headerGroupLevelFiveValue}
              name="headerGroupLevelFiveValue"
              onChange={this.onTextChange}
              heading="Group level 5"
              className="w-100"
              wrapClass="wrap"
              maxLength={50}
              isRequired={false}
              errorState={this.state.headerGroupLevelFiveValueState}
              errorMessage={this.state.headerGroupLevelFiveValueError}
            />
            <div className="row">
              <div className="col-12">
                <div className=" form-action d-flex justify-content-center">
                  <ButtonComponent
                    // id="btnCancel"
                    type="button"
                    value="Submit"
                    className="btn-primary  equal-size-button mr-4"
                    onClick={() => { this.updateGroupLevel('five') }} />
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* Download info popup */}
        <Modal
          centered={true}
          visible={this.state.downloadModalVisible}
          footer={false}
          onOk={() => this.setdownloadModalVisible(false)}
          onCancel={() => this.setdownloadModalVisible(false)}>
          <DownloadTemplate />
        </Modal>

        {/* Group settings popup */}
        <Modal
          maskClosable={false}
          closable={false}
          width={480}
          centered={true}
          visible={this.state.groupModalVisible}
          footer={false}
          bodyStyle={{ padding: '30px 40px' }}
          onOk={() => this.setGroupModalVisible(false)}
          onCancel={() => this.setGroupModalVisible(false)}>
          <GroupSettings
            groupLevel1={this.state.groupLevel1}
            groupLevel2={this.state.groupLevel2}
            groupLevel3={this.state.groupLevel3}
            groupLevel4={this.state.groupLevel4}
            groupLevel5={this.state.groupLevel5}
            showGroupLevel1={this.state.showGroupLevel1}
            showGroupLevel2={this.state.showGroupLevel2}
            showGroupLevel3={this.state.showGroupLevel3}
            showGroupLevel4={this.state.showGroupLevel4}
            showGroupLevel5={this.state.showGroupLevel5}
            redirectToRequirement={this.redirectToRequirement}
            redirectToHome={this.redirectToHome}
            totalRequirementCount={this.state.totalRequirementCount}
            analysisId={this.state.analysisId} />
        </Modal>

        {/* configure popup */}
        <Modal
          centered={true}
          width={1200}
          visible={this.state.showConfigurePopup}
          bodyStyle={{ padding: 0 }}
          footer={false}
          onOk={() => this.setShowonfigurePopup(false)}
          onCancel={() => this.setShowonfigurePopup(false)}>
          <div className="analysisWrap pt-3 pb-3 p-sm-4 p-md-5 analysisSheetData newAnalysisSheetData">
            {
              this.state.showSheetsDropDown
                ?
                <DropdownList
                  value={this.state.dropDownForSheet}
                  name="dropDownForSheet"
                  onChange={this.onSheetsDropDownTextChange}
                  placeholder="Select"
                  className="w-100"
                  wrapClass="wrap d-inline-block"
                  isRequired={false}
                  isDisabled={false}
                  options={this.state.sheetsArray}
                />
                :
                null
            }
            <ReactTable
              // ref={this.tblRef}
              hidePagination={true}
              columns={this.state.sheetTableColumns}
              data={this.state.sheetTableData}
              loading={this.state.loading}
              onTableChange={this.onTableChange}
              // totalRecords={this.state.totalRecords}
              // onRowClick={this.onRowClick}
              className="uploadFileTable"
            />

            <div className="intro">
              -Showing first 5 requirements from the uploaded file<br></br>
              -You can select which sheet and columns you want to use for analysis<br>
              </br>
              -You have to select atleast unique Id and requirement text columns. Group level 1 and Group level 2 columns are optional.
            </div>

            <div className="intro" style={{ color: 'red' }}>
              {
                Object.keys(this.state.warningsInfoForSheet).length > 0
                  ?
                  <>
                    <div>
                      {/* {this.state.warningsInfoForSheet.requirement_no ? this.state.warningsInfoForSheet.requirement_no : ''} */}
                      {this.state.warningsInfoForSheet.requirement_no  ? (
  this.state.warningsInfoForSheet.requirement_no .split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ))
) : ''}
                    </div>
                    <div>
                      {/* {this.state.warningsInfoForSheet.requirement_text ? this.state.warningsInfoForSheet.requirement_text : ''} */}
                      {this.state.warningsInfoForSheet.requirement_text ? (
  this.state.warningsInfoForSheet.requirement_text.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ))
) : ''}
                    </div>
                  </>
                  :
                  null
              }
            </div>

            <div style={{ height: 10 }} />
            <div className="row">
              <div className="col-12">
                <div className=" form-action d-flex justify-content-center">
                  <ButtonComponent id="btnCancel" type="button" value="Cancel"
                    className="btn-secondary equal-size-button mr-4" onClick={() => { this.setShowonfigurePopup(false) }} />
                  <ButtonComponent id="btnSubmit" type="button" value="Next" disabled={!this.state.nextFlag}
                    className={`btn-primary equal-size-button ${!this.state.nextFlag? 'disabledNextFlag':''}`} onClick={this.onConfigureTableNextClick}  />
                </div>
              </div>
            </div>
            {/* <table border="1" style={{ backgroundColor: '#f3f7fa', width: '100%' }}>
              <thead style={{ display: 'flex', flexDirection: 'row' }}>
                {
                  this.state.sheetTableColumns && this.state.sheetTableColumns.map((item, index) => {
                    return (
                      <tr>
                        <th className="text-center">
                          <div>
                            <DropdownList
                              value={''}
                              name={''}
                              onChange={this.onTextChange}
                              placeholder="Select"
                              className="w-100"
                              wrapClass="wrap"
                              isRequired={false}
                              isDisabled={false}
                              options={dropDownArray}
                            />
                            {item.Name}
                          </div>
                        </th>
                      </tr>
                    )
                  })
                }
              </thead>
              <tbody>
                {
                  this.state.sheetTableData && this.state.sheetTableData.map((item, index) => {
                    return (
                      <tr>
                        <td>{}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table> */}
          </div>
        </Modal>

        {this.state.isLoading && <Spin size="large" />}

      </div >
    );
  }
}

export default NewAnalysis;
