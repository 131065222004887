import React from "react";
import userIcon from "assets/img/user.svg";
import projectLogo from "assets/img/logoMain.png";
import Help from "views/popup/Help";
import { IconMarin } from 'views/popup/SvgSprite';
import { routes } from "routes/Routes";
import { withRouter } from "react-router-dom";
import { Drawer, Tooltip, Modal } from "antd";
import SearchBox from "components/controls/SearchBox";
import _authService from "modules/AuthService";
import Profile from "views/popup/Profile";
import ConfirmPopup from "views/popup/ConfirmPopup";
import ChangePassword from "views/popup/ChangePassword";
import {
  USER_NAME, PROFILE_URL, Last_LOGIN_DATETIME, USER_ROLE,
  ROLE_USER, ROLE_SUPER_ADMIN, ROLE_ADMIN
} from "constants/constants";
import moment from 'moment';

class PrivateHeader extends React.Component {
  constructor(props) {
    super(props);
    this.changePasswordModalRef = React.createRef();
    this.profileModalRef = React.createRef();
    this.analysisId = this.props.match.params.analysisId;
    this.state = {
      visible: false,
      ConfirmPopup: false,
      ChangePass: false,
      showProfileModal: false,
      username: localStorage[USER_NAME],
      profileURL: localStorage[PROFILE_URL],
      lastLoginDate: localStorage[Last_LOGIN_DATETIME]
    };
  }

  componentDidMount() {
    this.setState({
      lastLoginDate: moment(localStorage[Last_LOGIN_DATETIME]).calendar()
    });
  }

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  ConfirmPopup(ConfirmPopup) {
    this.setState({ ConfirmPopup });
  }

  ChangePass(ChangePass) {
    this.setState({ ChangePass });
    if (!ChangePass) {
      this.changePasswordModalRef.current.handleCancel();
    }
  }

  SetProfileModal(showProfileModal) {
    this.setState({ showProfileModal });
    if (!showProfileModal) {
      this.profileModalRef.current.handleCancel();
    }
  }

  logout = () => {
    _authService.logout();
  };

  goToHome = ev => {
    ev.preventDefault();
    this.props.history.push(routes.home.basePath);
  };

  updateProfile = (userName, profileUrl) => {
    this.setState({ username: userName, profileURL: profileUrl });
    localStorage[USER_NAME] = userName;
    localStorage[PROFILE_URL] = profileUrl;
  }

  render() {
    const DrawerTitle = (
      <div className="drawerTitle d-flex justify-content-between">
        <h5 className="m-0">Help</h5>
        <a onClick={this.onClose} style={{ fontSize: "26px" }}>
          ×
        </a>
      </div>
    );
    return (
      <header className="privateHeaderMain">
        <div className="container-fluid">
          <nav className="navbar  navbar-expand-lg justify-content-between navbar-light p-0">
            <div className="logoWrap d-flex">
              {this.props.location.pathname != "/home" ? (
                <div className="homeHeader pr-3 border-right">
                  <span onClick={this.goToHome}>
                    <IconMarin iconName="home" fill="#fff" class="iconMain" />
                  </span>
                </div>
              ) : (
                  ""
                )}
              <a className="navbar-brand pl-2" href="" onClick={this.goToHome}>
                {/* <h2 className="m-0 fontNunito font-medium">
                  {projectName} <small>Powered by Logapps</small>
                </h2> */}
                <img src={projectLogo} className="projectLogo" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div id="navbarSearch" className="justify-content-center pl-4 pr-4">
              {this.props.showSearch && (
                <SearchBox name="headerSearch" analysisId={this.analysisId} />
              )}
            </div>
            <div id="navbarSupportedContent" className=" justify-content-end">
              <ul className="nav navbar-nav align-items-center justify-content-end">
                <li className="nav-item userInfo dropdown mr-sm-3">
                  <a
                    className="nav-link pt-0 pb-0 dropdown-toggle d-flex align-items-center"
                    href="#"
                    id="navbardrop"
                    data-toggle="dropdown"
                  >
                    <span className="userIconWrap mr-1 d-inline-flex align-items-center justify-content-center">
                      <img src={this.state.profileURL != "" ? this.state.profileURL : userIcon} height="25px" />
                    </span>
                    <span className="ml-2">
                      <label className="mb-0 useName">
                        {this.state.username}

                        <IconMarin iconName="down-arrow" fill="#fff" class="icon" />
                      </label>
                      {/* <small>
                        {this.state.lastLoginDate}
                      </small> */}
                    </span>
                  </a>
                  <div className="topheaderdropdown dropdown-menu dropdown-menu-right ">
                    <div className=" dropdown-item p-0">
                      <div
                        className=" dropdown-item p-2"
                        onClick={() => this.SetProfileModal(true)}
                      >
                        <IconMarin iconName="userProfile" fill="#797979" class="icon mr-2" />
                        Profile
                      </div>
                    </div>
                    <div className="dropdown-divider m-0"></div>
                    <div className=" dropdown-item p-0">
                      <div
                        className=" dropdown-item p-2"
                        onClick={() => this.ChangePass(true)}
                      >
                        <IconMarin iconName="key" fill="#797979" class="icon mr-2" />
                        Change Password
                      </div>
                    </div>
                    {localStorage[USER_ROLE] != ROLE_USER &&
                      <div>
                        <div className="dropdown-divider m-0"></div>
                        <div
                          className=" dropdown-item p-2"
                          onClick={() => this.props.history.push(
                            routes.users.basePath
                          )}
                        >
                          <IconMarin iconName="userProfile" fill="#797979" class="icon mr-2" />
                          Users
                    </div>
                      </div>
                    }
                    {(localStorage[USER_ROLE] == ROLE_SUPER_ADMIN || localStorage[USER_ROLE] == ROLE_ADMIN) &&
                      <div>
                        <div>
                          <div className="dropdown-divider m-0"></div>
                          <div
                            className=" dropdown-item p-2"
                            onClick={() => this.props.history.push(
                              routes.userlogs.basePath
                            )}
                          >
                            <IconMarin iconName="userProfile" fill="#797979" class="icon mr-2" />
                          History Logs
                    </div>
                        </div>
                        {(localStorage[USER_ROLE] == ROLE_SUPER_ADMIN) &&
                          <div>
                            <div className="dropdown-divider m-0"></div>
                            <div
                              className=" dropdown-item p-2"
                              onClick={() => this.props.history.push(
                                routes.companies.basePath
                              )}
                            >
                              <IconMarin iconName="userProfile" fill="#797979" class="icon mr-2" />
                          Companies
                    </div>
                          </div>
                        }
                      </div>
                    }

                    <div className="dropdown-divider m-0"></div>
                    <div
                      className=" dropdown-item p-2"
                      onClick={() => this.ConfirmPopup(true)}
                    >
                      <IconMarin iconName="logout-icon" fill="#797979" class="icon mr-2" />
                      Logout
                    </div>
                  </div>
                </li>
                <li
                  className="nav-item cursor-pointer mr-sm-0 nav-link"
                  onClick={this.showDrawer}
                >
                  <Tooltip title="Help">
                    <IconMarin iconName="help" fill="#ffffff" class="icon" />
                  </Tooltip>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <Drawer
          width={350}
          title={DrawerTitle}
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          getContainer={false}
          style={{ position: "absolute" }}
        >
          <Help goHome={this.goToHome} />
        </Drawer>
        {/* ConformationPop Modal */}
        <Modal
          centered={true}
          width={500}
          bodyStyle={{
            height: "250px",
            padding: 0,
            alignItems: "center",
            justifyContent: "center",
            display: " flex"
          }}
          className="modelPopAnt"
          visible={this.state.ConfirmPopup}
          onCancel={() => this.ConfirmPopup(false)}
          footer={false}
        >
          <ConfirmPopup
            onCancel={() => this.ConfirmPopup(false)}
            onOk={this.logout}
            message="Are you sure, you want to logout?"
          />
        </Modal>
        {/* Change Password Modal */}
        <Modal
          centered={true}
          width={600}
          // bodyStyle={{ height: '250px', padding: 0, alignItems: 'center', justifyContent: 'center', display: ' flex' }}
          className="modelPopAnt"
          visible={this.state.ChangePass}
          onCancel={() => this.ChangePass(false)}
          footer={false}
        >
          <ChangePassword
            ref={this.changePasswordModalRef}
            resetPass={this.objStateUpdate}
            closeModal={() => this.ChangePass(false)}
          />{" "}
        </Modal>

        {/* Profile Modal */}
        <Modal
          centered={true}
          width={800}
          ref={this.profileModalRef}
          className="modelPopAnt"
          visible={this.state.showProfileModal}
          onCancel={() => this.SetProfileModal(false)}
          footer={false}
        >
          <Profile
            ref={this.profileModalRef}
            showProfileModal={this.state.showProfileModal}
            updateProfile={this.updateProfile}
            isAddNewUser={false}
          />
        </Modal>
      </header>
    );
  }
}

export default withRouter(PrivateHeader);
